import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import './IndividualTransactions.scss'
import API from '../../../../Utility/API/API'
import Loader from '../../../../Images/loader.gif'
import { BsArrowLeft } from 'react-icons/bs'

function IndividualTransactions() {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [selecetedTransaction, setSelecetedTransaction] = useState({})
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchIndTaxTransaction(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setSelecetedTransaction(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='indtransaction'>
      {/* <div className='top_button'>
        <button
          onClick={() =>
            navigate(`/dashboard/tax_payers/${selecetedTransaction.taxPayerId}`)
          }
        >
          View Tax Payer
        </button>
      </div> */}
      <div className='back'>
        <div className='back_left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Back</h1>
        </div>
      </div>
      <div className='transactions_wrap'>
        <div className='list1'>
          <div className='card'>
            <p>Payer Name</p>
            <h2>{selecetedTransaction.payerName}</h2>
          </div>
          <div className='card'>
            <p>amount</p>
            <h2>#{parseInt(selecetedTransaction.amount).toLocaleString()}</h2>
          </div>
          <div className='card'>
            <p>Payment</p>
            <h2>{selecetedTransaction.payment}</h2>
          </div>
          <div className='card'>
            <p>MDA</p>
            <h2>{selecetedTransaction.mda}</h2>
          </div>
          <div className='card'>
            <p>Payment Reference</p>
            <h2>{selecetedTransaction.paymentReference}</h2>
          </div>
          <div className='card'>
            <p>Receipt No</p>
            <h2>{selecetedTransaction.receiptNo}</h2>
          </div>
          {Object.entries(selecetedTransaction.extraData).map((item, i) => (
            <div className='card' key={i + 1}>
              <p>{item[0]}</p>
              <h2>{item[1]}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default IndividualTransactions
