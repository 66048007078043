import React, { useState, useEffect } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Image,
} from '@react-pdf/renderer'
import { useNavigate, useParams } from 'react-router-dom'
import API from '../../../../Utility/API/API'
import { BsArrowLeft } from 'react-icons/bs'
import Loader from '../../../../Images/loader.gif'
import Logo from '../../../../Images/logo.png'

const styles = StyleSheet.create({
  view: {
    width: '100%',
  },
  page: {
    paddingTop: 35,
    paddingBottom: 65,
  },
  section: {
    margin: '0 10px',
    padding: 10,
  },
  head: {
    textAlign: 'center',
    margin: 10,
    display: 'flex',
    // justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
  },
  tophead: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingBottom: 10,
  },
  headText: {
    fontSize: '25px',
    fontWeight: 'bold',
    paddingLeft: 100,
  },
  rows: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderBottom: '2px solid #eee',
    paddingTop: 10,
  },
  card: {
    width: '150px',
    paddingBottom: '20px',
  },
  cardMid: {
    width: '240px',
    paddingBottom: '20px',
  },
  cardLong: {
    width: '500px',
    paddingBottom: '20px',
  },
  cardLabel: {
    fontSize: '12px',
    paddingBottom: '2px',
    textTransform: 'capitalize',
  },
  cardText: {
    fontSize: '10px',
  },
  image: {
    width: '40px',
    margin: '0 20px',
  },
})

function DocumentFile() {
  const authToken = sessionStorage.getItem('AdminId')
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [taxPayerData, setTaxPayerData] = useState({
    residency: {
      address: '',
      city: '',
      country: '',
      lga: '',
      nearestBusStop: '',
      numberOfNeighbours: 0,
      residentType: '',
      state: '',
      yearsOfStay: 0,
    },
    spouse: {
      firstName: '',
      lastName: '',
      tin: '',
      dateOfBirth: '',
      employerName: '',
    },
    children: [],
  })
  const [taxTransaction, setTaxTransaction] = useState([])
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: sessionStorage.getItem('Admin-Id'),
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchTaxPayer(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayerData(data)
          fetch(
            `${process.env.REACT_APP_API_URL}/tax-transactions?searchTerm=${data.odTin}&sortBy=expDate:asc&limit=20&page=1`,
            {
              headers: {
                'content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
              },
            }
          ).then((res) => {
            if (res.status === 200) {
              res.json().then((datas) => {
                setTaxTransaction(datas.results)
                setLoading(false)
              })
            } else if (res.status === 401) {
              RefreshToken()
            } else {
              res.json().then((data) => {})
            }
          })
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  const MyDocument = () => (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.head}>
          <Image style={styles.image} src={Logo} />
          <Text style={styles.headText}>Tax Payer Summary</Text>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={styles.tophead}>Personal Information.</Text>
          </View>
          <View style={styles.rows}>
            <View style={styles.card}>
              <Text style={styles.cardLabel}>First Name</Text>
              <Text style={styles.cardText}>{taxPayerData.firstName}</Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardLabel}>Middle Name</Text>
              <Text style={styles.cardText}>{taxPayerData.middleName}</Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardLabel}>Last Name</Text>
              <Text style={styles.cardText}>{taxPayerData.lastName}</Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardLabel}>Date of Birth</Text>
              <Text style={styles.cardText}>
                {new Date(taxPayerData.dateOfBirth).toDateString()}
              </Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardLabel}>Gender</Text>
              <Text style={styles.cardText}>
                {taxPayerData.title === 'Mr' ? 'Male' : 'Female'}
              </Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardLabel}>Marital Status</Text>
              <Text style={styles.cardText}>{taxPayerData.maritalStatus}</Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardLabel}>Phone Number</Text>
              <Text style={styles.cardText}>{taxPayerData.phone}</Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardLabel}>Email Address</Text>
              <Text style={styles.cardText}>{taxPayerData.email}</Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardLabel}>NIN</Text>
              <Text style={styles.cardText}>{taxPayerData.nin}</Text>
            </View>
            <View style={styles.cardLong}>
              <Text style={styles.cardLabel}>Address</Text>
              <Text style={styles.cardText}>
                {taxPayerData.residency.address},{taxPayerData.residency.city},{' '}
                {taxPayerData.residency.state} state.
              </Text>
            </View>

            {/* <View style={styles.card}>
              <Text style={styles.cardLabel}>Head</Text>
              <Text style={styles.cardText}>{taxPayerData.firstName}</Text>
            </View> */}
          </View>
        </View>
        <View style={styles.section}>
          <View>
            <Text style={styles.tophead}>Spouse and Kids Information.</Text>
          </View>
          {Object.keys(taxPayerData).includes('spouse') ? (
            <View style={styles.rows}>
              <View style={styles.card}>
                <Text style={styles.cardLabel}>Spouse First Name</Text>
                <Text style={styles.cardText}>
                  {taxPayerData.spouse.firstName}
                </Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardLabel}>Spouse Last Name</Text>
                <Text style={styles.cardText}>{taxPayerData.firstName}</Text>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardLabel}>Spouse Tax Number</Text>
                <Text style={styles.cardText}>{taxPayerData.spouse.tin}</Text>
              </View>
            </View>
          ) : (
            <Text style={styles.cardLabel}>Spouse details not uploaded</Text>
          )}
          {Object.keys(taxPayerData).includes('children') && (
            <View style={styles.rows}>
              {taxPayerData.children.map((item, i) => (
                <View style={styles.cardMid} key={i + 1}>
                  <Text style={styles.cardLabel}> Child {i + 1}. Name </Text>
                  <Text style={styles.cardText}>
                    {item.firstName} {item.lastName}
                  </Text>
                </View>
              ))}
            </View>
          )}
        </View>
        <View style={styles.section}>
          <View>
            <Text style={styles.tophead}>Assets Information.</Text>
          </View>
          {Object.keys(taxPayerData).includes('assets') ? (
            <>
              {taxPayerData.assets.map((item, i) => (
                <View style={styles.rows} key={i + 1}>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>application Id </Text>
                    <Text style={styles.cardText}>{item.applicationId}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>name </Text>
                    <Text style={styles.cardText}>{item.name}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>asset Type </Text>
                    <Text style={styles.cardText}>{item.assetType}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>date Of Purchase </Text>
                    <Text style={styles.cardText}>
                      {new Date(item.dateOfPurchase).toDateString()}
                    </Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>engine Number </Text>
                    <Text style={styles.cardText}>{item.engineNumber}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>chasis Number </Text>
                    <Text style={styles.cardText}>{item.chasisNumber}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>plate Number </Text>
                    <Text style={styles.cardText}>{item.plateNumber}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>model </Text>
                    <Text style={styles.cardText}>{item.model}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>color </Text>
                    <Text style={styles.cardText}>{item.color}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>purpose </Text>
                    <Text style={styles.cardText}>{item.purpose}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>capacity </Text>
                    <Text style={styles.cardText}>{item.capacity}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>officer </Text>
                    <Text style={styles.cardText}>{item.officer}</Text>
                  </View>
                </View>
              ))}
            </>
          ) : (
            <Text style={styles.cardLabel}>Assets not uploaded </Text>
          )}
        </View>
        <View style={styles.section}>
          <View>
            <Text style={styles.tophead}> Recent Tax Information.</Text>
          </View>
          {taxTransaction.length > 0 ? (
            <>
              {taxTransaction.map((item, i) => (
                <View style={styles.rows} key={item.id}>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>application Number</Text>
                    <Text style={styles.cardText}>
                      {item.applicationNumber}
                    </Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>file No</Text>
                    <Text style={styles.cardText}>{item.fileNo}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>Income</Text>
                    <Text style={styles.cardText}>{item.income}</Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>Current Reg Date </Text>
                    <Text style={styles.cardText}>
                      {new Date(item.CurrentRegDate).toDateString()}
                    </Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>exp Date </Text>
                    <Text style={styles.cardText}>
                      {new Date(item.expDate).toDateString()}
                    </Text>
                  </View>
                  <View style={styles.card}>
                    <Text style={styles.cardLabel}>station </Text>
                    <Text style={styles.cardText}>{item.station}</Text>
                  </View>
                </View>
              ))}
            </>
          ) : (
            <Text style={styles.cardLabel}>No Tax Transactions Uploaded </Text>
          )}
          <>
            {taxTransaction.map((item, i) => (
              <View style={styles.rows} key={item.id}>
                <View style={styles.card}>
                  <Text style={styles.cardLabel}>application Number</Text>
                  <Text style={styles.cardText}>{item.applicationNumber}</Text>
                </View>
                <View style={styles.card}>
                  <Text style={styles.cardLabel}>file No</Text>
                  <Text style={styles.cardText}>{item.fileNo}</Text>
                </View>
                <View style={styles.card}>
                  <Text style={styles.cardLabel}>Income</Text>
                  <Text style={styles.cardText}>{item.income}</Text>
                </View>
                <View style={styles.card}>
                  <Text style={styles.cardLabel}>Current Reg Date </Text>
                  <Text style={styles.cardText}>
                    {new Date(item.CurrentRegDate).toDateString()}
                  </Text>
                </View>
                <View style={styles.card}>
                  <Text style={styles.cardLabel}>exp Date </Text>
                  <Text style={styles.cardText}>
                    {new Date(item.expDate).toDateString()}
                  </Text>
                </View>
                <View style={styles.card}>
                  <Text style={styles.cardLabel}>station </Text>
                  <Text style={styles.cardText}>{item.station}</Text>
                </View>
              </View>
            ))}
          </>
        </View>
      </Page>
    </Document>
  )
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <>
      <div
        className='back'
        style={{
          padding: '10px 20px',
          marginTop: '-20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <BsArrowLeft
          style={{ fontSize: '30px', cursor: 'pointer' }}
          onClick={() => navigate(`/dashboard/tax_payers/${id}`)}
        />
        <PDFDownloadLink
          document={<MyDocument />}
          fileName={`${taxPayerData.firstName}-${taxPayerData.lastName}-summary.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              'Loading document...'
            ) : (
              <button className='orangeButton'>Download PDF</button>
            )
          }
        </PDFDownloadLink>
      </div>
      <PDFViewer style={{ width: '100%', height: '80vh', marginTop: '' }}>
        {MyDocument()}
      </PDFViewer>
    </>
  )
}

export default DocumentFile
