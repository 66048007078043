import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Loader from '../../../../Images/loader.gif'
import { useNavigate, useParams } from 'react-router-dom'
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs'
import { TbReceiptTax, TbCircleMinus } from 'react-icons/tb'
import API from '../../../../Utility/API/API'

function EditCorporate({ notify, fixVIP }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [formLoading, setFormLoading] = useState(false)
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const [loading, setLoading] = useState(true)
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  function convertToISO(dateString) {
    const parts = dateString.split('/') // Split the input string into parts

    // Check if the parts are valid
    if (parts.length !== 3) {
      notify('error', 'Invalid date, please check the dates.')
      setFormLoading(false)
    }

    const day = parts[0]
    const month = parts[1]
    const year = parts[2]

    // Create a new Date object using the parsed values
    const date = new Date(`${year}-${month}-${day}`)

    // Use toISOString to get the date in ISO 8601 format
    return date.toISOString()
  }

  const {
    register: TaxRegister,
    handleSubmit: TaxSubmit,
    formState: { errors: TaxErrors },
  } = useForm()
  const [selecetedTransaction, setSelecetedTransaction] = useState({})

  const onTxEnroll = (formData) => {
    setFormLoading(true)
    const object = {
      tin: formData.tin,
      tradeName: formData.tradeName,
      dateOfIncorporation: convertToISO(formData.dateOfIncorporation),
      commencementDate: convertToISO(formData.commencementDate),
      registrationDate: convertToISO(formData.registrationDate),
      registrationNumber: formData.registrationNumber,
      businessName: formData.businessName,
      organisationType: formData.organisationType,
      lineOfBusiness: formData.lineOfBusiness,
      phone: formData.phone,
      stateOfResidence: formData.stateOfResidence,
      isVip: formData.isVip,
      numberOfEmployees: formData.numberOfEmployees,
      email: formData.email,
      state: formData.stateOfResidence,
      landlord: formData.landlord,
      importer: formData.importer,
      exporter: formData.exporter,
      lga: formData.lga,
      city: formData.city,
      houseNo: formData.houseNo,
      street: formData.street,
    }

    fetch(
      `${process.env.REACT_APP_API_URL}/employers/${selecetedTransaction.id}`,
      {
        method: 'put',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(object),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Corporate User Edited successfully`)
            setTimeout(() => {
              window.location.replace(
                `/dashboard/corporate_single/${selecetedTransaction.id}`
              )
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }

  useEffect(() => {
    API.FetchIndCorporate(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setSelecetedTransaction(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='create_payment'>
      <div className='back'>
        <div className='back_left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Back</h1>
        </div>
      </div>
      <div className='enroll_main'>
        {formLoading && (
          <div className='formload'>
            <img src={Loader} alt='' />
          </div>
        )}
        <form className='left' onSubmit={TaxSubmit(onTxEnroll)}>
          <div className='top_form'>
            <div className='taxtrans'>
              <div className='left_top diff2'>
                <TbReceiptTax />
                <h2>Edit Corporate User</h2>
              </div>
            </div>
            <div className='top_six'>
              <div className='address'>
                <p>Trade Name</p>
                <input
                  type='text'
                  name='tradeName'
                  id=''
                  {...TaxRegister('tradeName')}
                  defaultValue={
                    selecetedTransaction.tradeName === null
                      ? 'nil'
                      : selecetedTransaction.tradeName
                  }
                />
              </div>
              <div className='address'>
                <p>Business Name</p>
                <input
                  type='text'
                  name='businessName'
                  id=''
                  {...TaxRegister('businessName')}
                  defaultValue={
                    selecetedTransaction.businessName === null
                      ? 'nil'
                      : selecetedTransaction.businessName
                  }
                />
              </div>
              <div className='card'>
                <p>TIN</p>
                <input
                  type='text'
                  name='tin'
                  {...TaxRegister('tin')}
                  defaultValue={
                    selecetedTransaction.tin === null
                      ? 'nil'
                      : selecetedTransaction.tin
                  }
                />
                {TaxErrors.tin && (
                  <h6 className='vError'>{TaxErrors.tin.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Enrollee Id</p>
                <input
                  type='text'
                  name='enrolleeId'
                  {...TaxRegister('enrolleeId')}
                  defaultValue={
                    selecetedTransaction.enrolleeId === null
                      ? 'nil'
                      : selecetedTransaction.enrolleeId
                  }
                />
              </div>
              <div className='card'>
                <p>Date Of Incorporation</p>
                <input
                  type='text'
                  name='dateOfIncorporation'
                  id=''
                  {...TaxRegister('dateOfIncorporation')}
                  defaultValue={new Date(
                    `${selecetedTransaction.dateOfIncorporation}`
                  ).toLocaleDateString()}
                />
              </div>
              <div className='card'>
                <p>Commencement Date</p>
                <input
                  type='text'
                  name='commencementDate'
                  id=''
                  {...TaxRegister('commencementDate')}
                  defaultValue={new Date(
                    `${selecetedTransaction.commencementDate}`
                  ).toLocaleDateString()}
                />
                {TaxErrors.commencementDate && (
                  <h6 className='vError'>
                    {TaxErrors.commencementDate.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>Registration Date</p>
                <input
                  type='text'
                  name='registrationDate'
                  id=''
                  {...TaxRegister('registrationDate')}
                  defaultValue={new Date(
                    `${selecetedTransaction.registrationDate}`
                  ).toLocaleDateString()}
                />
                {TaxErrors.registrationDate && (
                  <h6 className='vError'>
                    {TaxErrors.registrationDate.message}
                  </h6>
                )}
              </div>

              <div className='card'>
                <p>Registration Number</p>
                <input
                  type='text'
                  name='registrationNumber'
                  defaultValue={
                    selecetedTransaction.registrationNumber === null
                      ? 'nil'
                      : selecetedTransaction.registrationNumber
                  }
                  {...TaxRegister('registrationNumber')}
                />
                {TaxErrors.registrationNumber && (
                  <h6 className='vError'>
                    {TaxErrors.registrationNumber.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>Organisation Type</p>
                <input
                  type='text'
                  name='organisationType'
                  defaultValue={selecetedTransaction.organisationType}
                  {...TaxRegister('organisationType')}
                />
              </div>
              <div className='card'>
                <p>Line Of Business</p>
                <input
                  type='text'
                  name='lineOfBusiness'
                  defaultValue={selecetedTransaction.lineOfBusiness}
                  {...TaxRegister('lineOfBusiness')}
                />
              </div>
              <div className='card'>
                <p>Phone</p>
                <input
                  type='tel'
                  name='phone'
                  defaultValue={selecetedTransaction.phone}
                  {...TaxRegister('phone')}
                />
                {TaxErrors.phone && (
                  <h6 className='vError'>{TaxErrors.phone.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Email</p>
                <input
                  type='email'
                  name='email'
                  defaultValue={selecetedTransaction.email}
                  {...TaxRegister('email')}
                />
                {TaxErrors.email && (
                  <h6 className='vError'>{TaxErrors.email.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Number Of Employees</p>
                <input
                  type='number'
                  name='numberOfEmployees'
                  defaultValue={
                    selecetedTransaction.numberOfEmployees === null
                      ? 'nil'
                      : selecetedTransaction.numberOfEmployees
                  }
                  {...TaxRegister('numberOfEmployees')}
                />
                {TaxErrors.numberOfEmployees && (
                  <h6 className='vError'>
                    {TaxErrors.numberOfEmployees.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>House No</p>
                <input
                  type='text'
                  name='houseNo'
                  defaultValue={selecetedTransaction.houseNo}
                  {...TaxRegister('houseNo')}
                />
                {TaxErrors.houseNo && (
                  <h6 className='vError'>{TaxErrors.houseNo.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Street</p>
                <input
                  type='text'
                  name='street'
                  defaultValue={selecetedTransaction.street}
                  {...TaxRegister('street')}
                />
                {TaxErrors.street && (
                  <h6 className='vError'>{TaxErrors.street.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>City</p>
                <input
                  type='text'
                  name='city'
                  defaultValue={selecetedTransaction.city}
                  {...TaxRegister('city')}
                />
                {TaxErrors.city && (
                  <h6 className='vError'>{TaxErrors.city.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>LGA</p>
                <input
                  type='text'
                  name='lga'
                  defaultValue={
                    selecetedTransaction.lga === null
                      ? 'nil'
                      : selecetedTransaction.lga
                  }
                  {...TaxRegister('lga')}
                />
                {TaxErrors.lga && (
                  <h6 className='vError'>{TaxErrors.lga.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>State Of Residence</p>
                <input
                  type='text'
                  name='stateOfResidence'
                  defaultValue={selecetedTransaction.stateOfResidence}
                  {...TaxRegister('stateOfResidence')}
                />
                {TaxErrors.stateOfResidence && (
                  <h6 className='vError'>
                    {TaxErrors.stateOfResidence.message}
                  </h6>
                )}
              </div>
              {fixVIP && (
                <div className='card'>
                  <p>VIP</p>
                  <select name='' id='' {...TaxRegister('isVip')}>
                    <option value={selecetedTransaction.isVip}>
                      {selecetedTransaction.isVip === false ? 'No' : 'Yes'}
                    </option>
                    {selecetedTransaction.isVip === false ? (
                      <option value='true'>Yes</option>
                    ) : (
                      <option value='false'>No</option>
                    )}
                  </select>
                </div>
              )}
              <div className='card'>
                <p>Landlord</p>
                <select name='' id='' {...TaxRegister('landlord')}>
                  <option value={selecetedTransaction.landlord}>
                    {selecetedTransaction.landlord === false ? 'No' : 'Yes'}
                  </option>
                  {selecetedTransaction.landlord === false ? (
                    <option value='true'>Yes</option>
                  ) : (
                    <option value='false'>No</option>
                  )}
                </select>
              </div>
              <div className='card'>
                <p>Importer</p>
                <select name='' id='' {...TaxRegister('importer')}>
                  <option value={selecetedTransaction.importer}>
                    {selecetedTransaction.importer === false ? 'No' : 'Yes'}
                  </option>
                  {selecetedTransaction.importer === false ? (
                    <option value='true'>Yes</option>
                  ) : (
                    <option value='false'>No</option>
                  )}
                </select>
              </div>
              <div className='card'>
                <p>Exporter</p>
                <select name='' id='' {...TaxRegister('exporter')}>
                  <option value={selecetedTransaction.exporter}>
                    {selecetedTransaction.exporter === false ? 'No' : 'Yes'}
                  </option>
                  {selecetedTransaction.exporter === false ? (
                    <option value='true'>Yes</option>
                  ) : (
                    <option value='false'>No</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className='submit'>
            <button>
              <p>Submit</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditCorporate
