const authToken = sessionStorage.getItem('AdminId')
class APIServices {
  async FetchFileDataCategory(num, category) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/file-data?category=${category}&limit=12&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchFileData(num) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/file-data?limit=12&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchReceipts(num) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/receipts?limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchReceiptsCategory(num, being) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/receipts?being=${being}&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchReceipt(id) {
    return await fetch(`${process.env.REACT_APP_API_URL}/receipts/${id}`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  }
  async FetchUsers(num) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/users?limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchCorporate(num) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/employers?limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchIndCorporate(id) {
    return await fetch(`${process.env.REACT_APP_API_URL}/employers/${id}`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  }
  async FetchUser(id) {
    return await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  }
  async FetchTaxPayers(num) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/tax-payers?limit=20&sortBy=firstName:asc&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchTaxPayer(id) {
    return await fetch(`${process.env.REACT_APP_API_URL}/tax-payers/${id}`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  }
  async FetchTaxPayerDrill(param, val) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/tax-payers?${param}=${val}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchETax(num) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/e-tax?sortBy=fileName:asc&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchBulkUploads(num) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/bulk-uploads?sortBy=createdDate:desc&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchTaxTransaction(num) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/tax-transactions?page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchPaymentTransaction(num) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/payment-transactions?sortBy=customerName:asc&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchIndTaxTransaction(id) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/tax-transactions/${id}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchIndPaymentTransaction(id) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/payment-transactions/${id}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchTaxpayerMetric() {
    return await fetch(`${process.env.REACT_APP_API_URL}/metrics/tax-payer`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  }
  async FetchSummaryMetric() {
    return await fetch(`${process.env.REACT_APP_API_URL}/metrics/summary`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  }
  async FetchEmployerMetric() {
    return await fetch(`${process.env.REACT_APP_API_URL}/metrics/employer`, {
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  }
  async FetchReceiptMetric(page) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/metrics/receipt/by-being?page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchFileDataMetric(page) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/metrics/file-data/by-category?page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async SearchFileCategories() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/metrics/file-data/by-category?limit=200&page=1`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FindLinkedData(id, type, page) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/tax-payers/${id}/${type}?limit=20&page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchCategoriesWithIds() {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/file-data/categories-with-ids?limit=50&page=1`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
  async FetchBulkError(id, count, page) {
    return await fetch(
      `${process.env.REACT_APP_API_URL}/bulk-uploads/${id}/errors?limit=${count}&page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }
}

const instance = new APIServices()

export default instance
