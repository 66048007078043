import React from 'react'
import './Topbar.scss'
import Logo from '../../../../Images/logo.png'
import { RiUser3Line, RiArrowDownSLine } from 'react-icons/ri'
import { FaBars } from 'react-icons/fa'
import { useGlobalContext } from '../../../../Utility/Context/Context'

function Topbar({ loginData }) {
  const { handleSidebarToggle } = useGlobalContext()
  return (
    <div className='topbar'>
      <div className='logo'>
        <div className='toggle'>
          <FaBars onClick={() => handleSidebarToggle()} />
        </div>
        <img src={Logo} alt='' />
      </div>
      <div className='rest'>
        <div className='page_name'>
          <h1>ONDO STATE ELECTRONIC DATA MANAGEMENT SYSTEM</h1>
        </div>
        <div className='may_account'>
          <div className='account_head'>
            <RiUser3Line className='user' />
            <div className='tag'>
              <h1>
                {loginData.firstName} {loginData.lastName}
              </h1>
              <p>{loginData.role}</p>
            </div>
            {/* <RiArrowDownSLine /> */}
          </div>
          <div className='account_body'>
            <ul>
              <li>Hey</li>
              <li>Hey</li>
              <li>Hey</li>
              <li>Hey</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Topbar
