import React, { useState, useEffect } from 'react'
import './LinkData.scss'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BsCheckCircleFill } from 'react-icons/bs'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import Loader from '../../../../Images/loader.gif'
import Pagination from '../Pagination/Pagination'

function LinkData({ notify }) {
  const { handleLinkData, linkdata } = useGlobalContext()
  const authToken = sessionStorage.getItem('AdminId')
  const [dataPage, setDataPage] = useState('main')
  const [modalPage, setModalPage] = useState('none')
  const [loading, setLoading] = useState(false)
  const [taxPayer, setTaxPayer] = useState([])
  const [taxPayerId, setTaxPayerId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')

  const shootSearch = (e) => {
    e.preventDefault()
    // navigate(
    //   `/dashboard/search-result?search=${encodeURIComponent(
    //     e.target.search.value
    //   )}`
    // )
    setLoading(true)
    setSearchValue(e.target.search.value)
    fetch(
      `${process.env.REACT_APP_API_URL}/tax-payers?searchTerm=${e.target.search.value}&sortBy=firstName:asc&limit=20&page=1`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
          setDataPage('link')
        })
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const selectUser = (id) => {
    setTaxPayerId(id)
    setModalPage('ask')
  }
  const LinkingData = () => {
    let url = ''
    if (linkdata.uploadType === 'e-tax') {
      url = `${process.env.REACT_APP_API_URL}/e-tax/${linkdata.id}/tax-payer`
    } else if (linkdata.hasOwnProperty('fileData')) {
      url = `${process.env.REACT_APP_API_URL}/file-data/${linkdata.id}/tax-payer`
    } else if (linkdata.hasOwnProperty('printedBy')) {
      url = `${process.env.REACT_APP_API_URL}/receipts/${linkdata.id}/tax-payer`
    } else {
      url = `${process.env.REACT_APP_API_URL}/tax-transactions/${linkdata.id}/tax-payer`
    }
    setLoading(true)
    fetch(url, {
      method: 'put',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        taxPayerId: taxPayerId.id,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLoading(false)
          setModalPage('done')
        })
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setLoading(false)
        })
      }
    })
  }
  const paginationClick = (num) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/tax-payers?searchTerm=${searchValue}&sortBy=firstName:asc&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
          setDataPage('link')
        })
      } else {
        res.json().then((data) => {})
      }
    })
  }

  return (
    <div className='LinkData'>
      {modalPage === 'ask' && (
        <div className='modals'>
          <div className='modal-c'>
            <h2>Link Data</h2>
            <p>
              Are you sure you want to link this data to {taxPayerId.firstName}{' '}
              {''}
              {taxPayerId.lastName}?
            </p>
            <div className='buttons'>
              {loading ? (
                <img src={Loader} alt='' />
              ) : (
                <>
                  <button onClick={() => setModalPage('none')}>Cancel</button>
                  <button onClick={() => LinkingData()}>Link Data</button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {modalPage === 'done' && (
        <div className='modals'>
          <div className='modal-c'>
            <BsCheckCircleFill />
            <h1>Link Data</h1>
            <p>Great, data successfully linked</p>
            <div className='buttons'>
              <button className='sd' onClick={() => window.location.reload()}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      {dataPage === 'main' && (
        <div className='linkdata-body'>
          <div className='top'>
            <div className='bg'>
              <h2>
                {linkdata && linkdata.uploadType === 'e-tax'
                  ? linkdata.fileName
                  : ''}
                {linkdata && linkdata.uploadType !== 'e-tax'
                  ? linkdata.customerName
                  : ''}
              </h2>
              <div className='hr'>
                <p>Not Linked</p>
                <button onClick={() => setDataPage('link')}>Link Data</button>
              </div>
            </div>
            <div className='close'>
              <p>Not Linked</p>
              <AiOutlineCloseCircle onClick={() => handleLinkData()} />
            </div>
          </div>
          {/* "FBN|BRH|ONDSR|29-03-2019|581134" */}
          <form className='search' onSubmit={shootSearch}>
            <input
              type='text'
              required
              placeholder='Tax Payer name'
              name='search'
            />
            <div className='buttons'>
              {loading ? <img src={Loader} alt='' /> : <button>Search</button>}
            </div>
          </form>
          {/* <div className='data-body'>
            <div className='row'>
              <div className='card'>
                <p>FIRST NAME</p>
                <h2>Peter</h2>
              </div>
              <div className='card'>
                <p>MIDDLE NAME</p>
                <h2>Taiwo</h2>
              </div>
              <div className='card'>
                <p>LAST NAME</p>
                <h2>Omiwole</h2>
              </div>
            </div>
            <div className='row'>
              <div className='card X'>
                <p>CURRENT RESIDENTIAL ADDRESS</p>
                <h2>
                  34a, Ago Iwoye Street, Agric B/Stop, Ikorodu, Lagos, Nigeria
                </h2>
              </div>
            </div>
            <div className='row'>
              <div className='card'>
                <p>NATIONALITY</p>
                <h2>Nigeria</h2>
              </div>
              <div className='card'>
                <p>OCCUPATION</p>
                <h2>Art Director</h2>
              </div>
              <div className='card'>
                <p>LOCAL GOVT. AREA</p>
                <h2>Ikorodu LGA</h2>
              </div>
            </div>
            <div className='row'>
              <div className='card'>
                <p>CONTACT PHONE NUMBER</p>
                <h2>07067421332</h2>
              </div>
              <div className='card'>
                <p>EMAIL ADDRESS</p>
                <h2>fredstaps@yahoo.com</h2>
              </div>
            </div>
            <div className='row'>
              <div className='card Y'>
                <p>VALID ID CARD</p>
                <h2>National Identity</h2>
              </div>
              <div className='card Y'>
                <p>ID NUMBER</p>
                <h2>CAC5635 63838 9392 0018</h2>
              </div>
            </div>
          </div> */}
        </div>
      )}
      {dataPage === 'link' && (
        <div className='linkdata-body'>
          <div className='top'>
            <h2>Link to Taxpaye</h2>
            <div className='close'>
              <AiOutlineCloseCircle onClick={() => setDataPage('main')} />
            </div>
          </div>
          <div className='data-body'>
            {taxPayer.results.map((item) => (
              <div
                className='row dif'
                key={item.id}
                onClick={() => selectUser(item)}
              >
                <div className='card'>
                  <p>
                    {item.firstName} {''}
                    {item.middleName} {''}
                    {item.lastName}
                  </p>
                </div>
                <div className='card'>
                  <p> {item.phone}</p>
                </div>
                <div className='card'>
                  <p> {item.email}</p>
                </div>
              </div>
            ))}
            {taxPayer.results.length < 1 && (
              <div className='row dif'>
                <div className='card'>
                  <p>No Taxpayer found</p>
                </div>
                <div className='card'></div>
              </div>
            )}
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={taxPayer.totalPages}
              pageSize={10}
              onPageChange={(page) => {
                setCurrentPage(page)
                paginationClick(page)
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default LinkData
