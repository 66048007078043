import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import API from '../../../../Utility/API/API'
import Loader from '../../../../Images/loader.gif'
import { BsArrowLeft } from 'react-icons/bs'
import { useGlobalContext } from '../../../../Utility/Context/Context'

function Receipt() {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const { handleLinkData } = useGlobalContext()

  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [selectedTransaction, setSelectedTransaction] = useState({})

  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchReceipt(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setSelectedTransaction(data)

          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='indtransaction'>
      <div className='top_button'>
        {selectedTransaction.taxPayerId.length > 0 ? (
          <button
            onClick={() =>
              navigate(
                `/dashboard/tax_payers/${selectedTransaction.taxPayerId}`
              )
            }
          >
            View Tax Payer
          </button>
        ) : (
          <button onClick={() => handleLinkData(selectedTransaction)}>
            Link Tax Payer
          </button>
        )}
      </div>
      <div className='back'>
        <div className='back_left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Back</h1>
        </div>
      </div>
      <div className='transactions_wrap'>
        <div className='list1'>
          <div className='card'>
            <p>Payer Name</p>
            <h2>{selectedTransaction.payerName}</h2>
          </div>
          <div className='card'>
            <p>Receipt Number</p>
            <h2>{selectedTransaction.receiptNumber}</h2>
          </div>
          <div className='card'>
            <p>Being</p>
            <h2>{selectedTransaction.being}</h2>
          </div>
          <div className='card'>
            <p>MDA</p>
            <h2>{selectedTransaction.mda}</h2>
          </div>
          <div className='card'>
            <p>File Number</p>
            <h2>{selectedTransaction.fileNumber}</h2>
          </div>
          <div className='card'>
            <p>Amount</p>
            <h2>
              #{parseFloat(selectedTransaction.paymentAmount).toLocaleString()}
            </h2>
          </div>
          <div className='card'>
            <p>Payment Date</p>
            <h2>{new Date(selectedTransaction.paymentDate).toDateString()}</h2>
          </div>

          <div className='card'>
            <p>Bank Name</p>
            <h2>{selectedTransaction.bankName}</h2>
          </div>
          <div className='card'>
            <p>Teller Number</p>
            <h2>{selectedTransaction.tellerNumber}</h2>
          </div>
          <div className='card'>
            <p>RRR</p>
            <h2>{selectedTransaction.rrr}</h2>
          </div>
          <div className='cards'>
            <h2>Address</h2>
            <p>{selectedTransaction.address}</p>
          </div>

          <div className='card'>
            <p>Revenue Item Code</p>
            <h2>{selectedTransaction.revenueItemCode}</h2>
          </div>
          <div className='card'>
            <p>Revenue Head Code</p>
            <h2>{selectedTransaction.revenueHeadCode}</h2>
          </div>
          <div className='card'>
            <p>Printed By</p>
            <h2>{selectedTransaction.printedBy}</h2>
          </div>
          <div className='cards'>
            <p>Payment Reference</p>
            <h2>{selectedTransaction.paymentReference}</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Receipt
