import React, { useState, useEffect } from 'react'
import './SearchResult.scss'
import { FaSearch } from 'react-icons/fa'
import { useNavigate, useLocation } from 'react-router-dom'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import Loader from '../../../../Images/loader.gif'
import FileViewer from 'react-file-viewer'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import './pdfWorker'
import Pagination from '../../Component/Pagination/Pagination'

function SearchResult({ fixVIP, loginData }) {
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const navigate = useNavigate()
  const { handleLinkData, handleRestrictionrToggle } = useGlobalContext()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const myString = params.get('search')
  const [searchValue, setSearchValue] = useState(myString)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationType, setPaginationType] = useState('default')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const ReceiptDateFilter = (num) => {
    setLoading(true)
    setCurrentPage(num)
    fetch(
      `${process.env.REACT_APP_API_URL}/receipts?searchTerm=${searchValue}&startDate=${startDate}&endDate=${endDate}&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
          setPaginationType('filterDate')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const TransactionDateFilter = (num) => {
    setLoading(true)
    setCurrentPage(num)
    fetch(
      `${process.env.REACT_APP_API_URL}/payment-transactions?searchTerm=${searchValue}&from=${startDate}&to=${endDate}&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
          setPaginationType('filterDate')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const FileDataDateFilter = (num) => {
    setLoading(true)
    setCurrentPage(num)
    fetch(
      `${process.env.REACT_APP_API_URL}/file-data?searchTerm=${searchValue}&startDate=${startDate}&endDate=${endDate}&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
          setPaginationType('filterDate')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const paginationClick = (num) => {
    setLoading(true)
    TaxPayer(num)
    if (searchPage === 'taxpayer') {
      TaxPayer(num)
    } else if (searchPage === 'etax') {
      ETax(num)
    } else if (searchPage === 'corporate') {
      Corporate(num)
    } else if (searchPage === 'category' && paginationType === 'default') {
      Category(num)
    } else if (searchPage === 'transactions') {
      Transactions(num)
    } else if (searchPage === 'others' && paginationType === 'default') {
      Others(num)
    } else if (searchPage === 'othersCat') {
      OthersCat(num)
    } else if (searchPage === 'receipts' && paginationType === 'default') {
      Receipts(num)
    } else if (searchPage === 'receipts' && paginationType === 'filterDate') {
      ReceiptDateFilter(num)
    } else if (searchPage === 'category' && paginationType === 'filterDate') {
      TransactionDateFilter(num)
    } else if (searchPage === 'others' && paginationType === 'filterDate') {
      FileDataDateFilter(num)
    }
    // API.FetchTaxPayers(num).then((res) => {
    //   if (res.status === 200) {
    //     res.json().then((data) => {
    //       setTaxPayer(data)
    //       setLoading(false)
    //     })
    //   } else if (res.status === 401) {
    //     RefreshToken()
    //   } else {
    //     res.json().then((data) => {})
    //   }
    // })
  }
  ////////////////////////////////////////////////////
  const [searchPage, setSearchPage] = useState('taxpayer')
  const [loading, setLoading] = useState(true)
  const [taxPayer, setTaxPayer] = useState({})
  const [showPdf, setShowPdf] = useState(false)
  const [selectedEtax, setSelectedEtax] = useState('')

  const KillSwitch = () => {
    fetch(`${process.env.REACT_APP_API_URL}/file-data/delete`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        category: 'LUC',
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {})
        } else {
          res.json().then((data) => {})
        }
      })
      .catch((rr) => {})
  }
  const TaxPayer = (page) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/tax-payers?searchTerm=${searchValue}&sortBy=firstName:asc&limit=20&page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setPaginationType('default')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const ETax = (page) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/e-tax?searchTerm=${searchValue}&sortBy=firstName:asc&limit=20&page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setPaginationType('default')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const Corporate = (page) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/employers?searchTerm=${searchValue}&limit=20&page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setPaginationType('default')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const Category = (page) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/payment-transactions?searchTerm=${searchValue}&limit=20&page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setPaginationType('default')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const Others = (page) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/file-data?keyword=${searchValue}&limit=20&page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setPaginationType('default')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const OthersCat = (page) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/file-data?category=${searchValue}&limit=20&page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setPaginationType('default')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const Transactions = (page) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/tax-transactions?searchTerm=${searchValue}&limit=20&page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setPaginationType('default')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const Receipts = (page) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/receipts?searchTerm=${searchValue}&limit=20&page=${page}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setPaginationType('default')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }

  useEffect(() => {
    if (searchPage === 'taxpayer') {
      TaxPayer(1)
    } else if (searchPage === 'etax') {
      ETax(1)
    } else if (searchPage === 'corporate') {
      Corporate(1)
    } else if (searchPage === 'category') {
      Category(1)
    } else if (searchPage === 'transactions') {
      Transactions(1)
    } else if (searchPage === 'others') {
      Others(1)
    } else if (searchPage === 'othersCat') {
      OthersCat(1)
    } else if (searchPage === 'receipts') {
      Receipts(1)
    }
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    setStartDate('')
    setEndDate('')
    window.history.pushState(
      null,
      '',
      `/dashboard/search-result?search=${encodeURIComponent(searchValue)}`
    )
    if (searchPage === 'taxpayer') {
      TaxPayer(1)
    } else if (searchPage === 'etax') {
      ETax(1)
    } else if (searchPage === 'corporate') {
      Corporate(1)
    } else if (searchPage === 'category') {
      Category(1)
    } else if (searchPage === 'transactions') {
      Transactions(1)
    } else if (searchPage === 'others') {
      Others(1)
    } else if (searchPage === 'othersCat') {
      OthersCat(1)
    } else if (searchPage === 'receipts') {
      Receipts(1)
    }
  }
  const showPDFNow = (e) => {
    setSelectedEtax(e)
    if (e) {
      setShowPdf(true)
    }
  }
  return (
    <div className='searchResult'>
      {showPdf && (
        <div className='showPDF'>
          <div className='cover'>
            <div className='download'>
              <a href={`https://app.ondoedms.com${selectedEtax}`}>
                <button>Download PDF</button>
              </a>
              <AiOutlineCloseCircle onClick={() => setShowPdf(false)} />
            </div>
            <div className='file-cover'>
              <FileViewer
                fileType='pdf'
                filePath={`https://app.ondoedms.com${selectedEtax}`}
              />
            </div>
          </div>
        </div>
      )}
      <div className='top'>
        <form className='search' onSubmit={handleSubmit}>
          <input
            type='text'
            id=''
            placeholder='Iwinosa Igbinosa'
            value={searchValue}
            name='search'
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <button className='search-icon'>
            <FaSearch />
          </button>
        </form>
      </div>
      <div className='result-body'>
        <div className='tag'>
          <button
            className={searchPage === 'taxpayer' ? 'active' : ''}
            onClick={() => {
              setSearchPage('taxpayer')
              TaxPayer(1)
            }}
          >
            Tax-payer
          </button>
          <button
            className={searchPage === 'etax' ? 'active' : ''}
            onClick={() => {
              setSearchPage('etax')
              ETax(1)
            }}
          >
            PDF Records
          </button>
          <button
            className={searchPage === 'corporate' ? 'active' : ''}
            onClick={() => {
              setSearchPage('corporate')
              Corporate(1)
            }}
          >
            Corporate
          </button>
          {/* <button
            className={searchPage === 'category' ? 'active' : ''}
            onClick={() => {
              setSearchPage('category')
              Category(1)
            }}
          >
            Transaction by Category
          </button> */}
          {/* <button
            className={searchPage === 'receipts' ? 'active' : ''}
            onClick={() => {
              setSearchPage('receipts')
              Receipts(1)
            }}
          >
            Receipts
          </button> */}
          <button
            className={searchPage === 'transactions' ? 'active' : ''}
            onClick={() => {
              setSearchPage('transactions')
              Transactions(1)
            }}
          >
            Tax Transactions
          </button>

          <button
            className={searchPage === 'others' ? 'active' : ''}
            onClick={() => {
              setSearchPage('others')
              Others(1)
            }}
          >
            Other Data
          </button>
        </div>
        <div className='top-b'>
          {loading ? (
            <h1>...</h1>
          ) : (
            <>
              <p>
                About <b>{taxPayer.totalResults.toLocaleString()}</b> results
              </p>
            </>
          )}
        </div>
        {loading ? (
          <div className='loadx'>
            <img src={Loader} alt='' />
          </div>
        ) : (
          <div className='results-card'>
            {searchPage === 'taxpayer' && (
              <>
                {taxPayer.results.map((item) => (
                  <div
                    className='card linked'
                    key={item.id}
                    id={item.isVip ? 'blur' : ''}
                  >
                    <h1
                      onClick={() => {
                        if (fixVIP) {
                          navigate(`/dashboard/tax_payers/${item.id}`)
                        } else if (
                          loginData.role !== 'security admin' &&
                          item.isVip
                        ) {
                          handleRestrictionrToggle()
                        } else {
                          navigate(`/dashboard/tax_payers/${item.id}`)
                        }
                      }}
                    >
                      {item.firstName} {item.middleName} {item.lastName} -{' '}
                      {item.tittle} - {item.maritalStatus} -{' '}
                      <span>Tax-payer</span>
                    </h1>
                    <p
                      onClick={() =>
                        navigate(`/dashboard/tax_payers/${item.id}`)
                      }
                    >
                      Click to view more
                    </p>
                    {/* <div className='buttons'>
                      <button>Linked</button>
                    </div> */}
                  </div>
                ))}
              </>
            )}
            {searchPage === 'etax' && (
              <>
                {taxPayer.results.map((item) => (
                  <div
                    key={item.id}
                    className={
                      item.hasOwnProperty('taxPayerId') ? 'card linked' : 'card'
                    }
                  >
                    <h1 onClick={() => showPDFNow(item.fullPath)}>
                      {item.fileName} - <span>PDF</span>
                    </h1>
                    <p onClick={() => showPDFNow(item.fullPath)}>
                      click to open
                    </p>
                    <div className='buttons'>
                      {item.hasOwnProperty('taxPayerId') ? (
                        <button
                          onClick={() =>
                            navigate(`/dashboard/tax_payers/${item.taxPayerId}`)
                          }
                        >
                          Linked
                        </button>
                      ) : (
                        <button onClick={() => handleLinkData(item)}>
                          Unlinked
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            {searchPage === 'corporate' && (
              <>
                {taxPayer.results.map((item) => (
                  <div
                    className='card linked'
                    key={item.id}
                    onClick={() =>
                      navigate(`/dashboard/corporate_page/${item.id}`)
                    }
                  >
                    <h1>
                      {item.businessName} - <span>Corporate</span>
                    </h1>
                    <p>
                      {' '}
                      {item.houseNo} {item.street} {item.city}{' '}
                      {item.stateOfResidence}
                    </p>
                  </div>
                ))}
              </>
            )}
            {searchPage === 'category' && (
              <>
                <div className='date-filter'>
                  <div className='date-filter-cover'>
                    <div className='cardx'>
                      <input
                        type='date'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <p>To</p>
                    <div className='cardx'>
                      <input
                        type='date'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className='submit'>
                      <button onClick={() => TransactionDateFilter(1)}>
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
                {taxPayer.results.map((item) => (
                  <div
                    key={item.id}
                    className={
                      item.hasOwnProperty('taxPayerId') ? 'card linked' : 'card'
                    }
                  >
                    <h1
                      onClick={() =>
                        navigate(`/dashboard/payment_transaction/${item.id}`)
                      }
                    >
                      {item.customerName} -{item.paymentRefNumber} -{' '}
                      <span>Transaction by Category</span>
                    </h1>
                    <p
                      onClick={() =>
                        navigate(`/dashboard/payment_transaction/${item.id}`)
                      }
                    >
                      {item.mda}
                    </p>
                    <div className='buttons'>
                      {item.hasOwnProperty('taxPayerId') ? (
                        <button
                          onClick={() =>
                            navigate(
                              `/dashboard/payment_transaction/${item.id}`
                            )
                          }
                        >
                          Linked
                        </button>
                      ) : (
                        <button onClick={() => handleLinkData(item)}>
                          Unlinked
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            {searchPage === 'receipts' && (
              <>
                <div className='date-filter'>
                  <div className='date-filter-cover'>
                    <div className='cardx'>
                      <input
                        type='date'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <p>To</p>
                    <div className='cardx'>
                      <input
                        type='date'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className='submit'>
                      <button onClick={() => ReceiptDateFilter(1)}>
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
                {taxPayer.results.map((item) => (
                  <div
                    key={item.id}
                    className={
                      (Object.keys(item).includes('taxPayerId') &&
                        item.taxPayerId.length) > 0
                        ? 'card linked'
                        : 'card'
                    }
                  >
                    <h1
                      onClick={() => navigate(`/dashboard/receipt/${item.id}`)}
                    >
                      {item.payerName} - #
                      {parseFloat(item.paymentAmount).toLocaleString()} -
                      {item.being} -<span>Receipts</span>
                    </h1>
                    <p
                      onClick={() => navigate(`/dashboard/receipt/${item.id}`)}
                    >
                      {item.mda}
                    </p>
                    <div className='buttons'>
                      {(Object.keys(item).includes('taxPayerId') &&
                        item.taxPayerId.length) > 0 ? (
                        <button
                          onClick={() =>
                            navigate(`/dashboard/receipt/${item.id}`)
                          }
                        >
                          Linked
                        </button>
                      ) : (
                        <button onClick={() => handleLinkData(item)}>
                          Unlinked
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            {searchPage === 'transactions' && (
              <>
                {taxPayer.results.map((item) => (
                  <div
                    className={
                      (Object.keys(item).includes('taxPayerId') &&
                        item.taxPayerId.length) > 0
                        ? 'card linked'
                        : 'card'
                    }
                    key={item.id}
                  >
                    <h1
                      onClick={() =>
                        navigate(`/dashboard/tax_transactions/${item.id}`)
                      }
                    >
                      {item.payerName} - {item.mda} -
                      <span>Tax Transaction</span>
                    </h1>
                    <p
                      onClick={() =>
                        navigate(`/dashboard/tax_transactions/${item.id}`)
                      }
                    >
                      click to view more
                    </p>
                    <div className='buttons'>
                      {(Object.keys(item).includes('taxPayerId') &&
                        item.taxPayerId.length) > 0 ? (
                        <button
                          onClick={() =>
                            navigate(`/dashboard/tax_transactions/${item.id}`)
                          }
                        >
                          Linked
                        </button>
                      ) : (
                        <button onClick={() => handleLinkData(item)}>
                          Unlinked
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            {searchPage === 'others' && (
              <>
                <div className='date-filter'>
                  <div className='date-filter-cover'>
                    <div className='cardx'>
                      <input
                        type='date'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <p>To</p>
                    <div className='cardx'>
                      <input
                        type='date'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className='submit'>
                      <button onClick={() => FileDataDateFilter(1)}>
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
                {taxPayer.results.map((item) => (
                  <>
                    <div
                      key={item.id}
                      className={
                        item.hasOwnProperty('taxPayerId')
                          ? 'card linked'
                          : 'card'
                      }
                    >
                      <h1
                        onClick={() =>
                          navigate(`/dashboard/general_data/${item.id}`)
                        }
                      >
                        {item.fileData && (
                          <>
                            {Object.values(item.fileData)[1]} -{' '}
                            {Object.values(item.fileData)[2]} -{' '}
                          </>
                        )}

                        <span>{item.category}</span>
                      </h1>
                      <p
                        onClick={() =>
                          navigate(`/dashboard/general_data/${item.id}`)
                        }
                      >
                        click to view more
                      </p>
                      <div className='buttons'>
                        {item.hasOwnProperty('taxPayerId') ? (
                          <button
                            onClick={() =>
                              navigate(`/dashboard/general_data/${item.id}`)
                            }
                          >
                            Linked
                          </button>
                        ) : (
                          <button onClick={() => handleLinkData(item)}>
                            Unlinked
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
            {searchPage === 'othersCat' && (
              <>
                {taxPayer.results.map((item) => (
                  <>
                    <div
                      key={item.id}
                      className={
                        item.hasOwnProperty('taxPayer') ? 'card linked' : 'card'
                      }
                    >
                      <h1
                        onClick={() =>
                          navigate(`/dashboard/general_data/${item.id}`)
                        }
                      >
                        {Object.values(item.fileData)[1]} -{' '}
                        {Object.values(item.fileData)[2]} -{' '}
                        <span>{item.category}</span>
                      </h1>
                      <p
                        onClick={() =>
                          navigate(`/dashboard/general_data/${item.id}`)
                        }
                      >
                        click to view more
                      </p>
                      <div className='buttons'>
                        {item.hasOwnProperty('taxPayer') ? (
                          <button
                            onClick={() =>
                              navigate(`/dashboard/general_data/${item.id}`)
                            }
                          >
                            Linked
                          </button>
                        ) : (
                          <button onClick={() => handleLinkData(item)}>
                            Unlinked
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={taxPayer.totalPages}
              pageSize={10}
              onPageChange={(page) => {
                setCurrentPage(page)
                paginationClick(page)
              }}
            />

            {/* <div className='card' onClick={handleLinkData}>
              <h1>
                Peter Omiwole - Carpenter - Dennis and Sons - <span>Text</span>
              </h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500.
              </p>
              <div className='buttons'>
                <button>Not Linked</button>
              </div>
            </div> */}
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchResult
