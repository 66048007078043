import React, { useState, useEffect } from 'react'
import './UploadData.scss'
import {
  BsArrowLeft,
  BsCheckCircleFill,
  BsDownload,
  BsArrowRight,
} from 'react-icons/bs'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../../Images/loader.gif'
import API from '../../../../Utility/API/API'
import Pagination from '../../Component/Pagination/Pagination'
import CreatableSelect from 'react-select/creatable'

function UploadData({ notify }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const [formLoading, setFormLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const authToken = sessionStorage.getItem('AdminId')
  // const [uploadPage, setUploadPage] = useState('personal')
  const [bulkList, setBulkList] = useState({})
  const [fileName, setFileName] = useState('')
  const navigate = useNavigate()
  const { id } = useParams()
  const [currentPage, setCurrentPage] = useState(1)
  const [category, setCategory] = useState('')
  const [categories, setCategories] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [profilePicture, setProfilePicture] = useState({
    name: '',
    file: null,
  })
  const [imgTru, setImgTru] = useState(false)
  const onSelectProductImage = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (fileName.length === 0) {
      notify('warn', 'Please select an upload type first')
    } else {
      reader.onloadend = () => {
        setImgTru(true)
        setProfilePicture({
          name: file.name,
          file,
        })
      }
    }
  }
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const uploadData = () => {
    setFormLoading(true)
    const form_data = new FormData()
    form_data.append('uploadType', fileName)
    form_data.append('file', profilePicture.file)
    fetch(`${process.env.REACT_APP_API_URL}/bulk-uploads`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      // body: JSON.stringify(formData),
      body: form_data,
      redirect: 'follow',
    }).then((res) => {
      if (res.status === 201) {
        res.json().then((data) => {
          setFormLoading(false)
          notify('success', `Bulk upload successful`)
          setTimeout(() => {
            window.location.replace('/dashboard/upload_data/bulkList')
          }, 2000)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setFormLoading(false)
        })
      }
    })
  }
  useEffect(() => {
    API.FetchBulkUploads(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setBulkList(data)
          setLoading(false)
        })
      } else {
        res.json().then((data) => {
          setLoading(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])
  const paginationClick = (num) => {
    setLoading(true)
    API.FetchBulkUploads(num).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setBulkList(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const uploadDumpData1 = () => {
    setFormLoading(true)
    const form_data = new FormData()
    form_data.append('category', category)
    form_data.append('file', profilePicture.file)
    fetch(`${process.env.REACT_APP_API_URL}/file-data`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: form_data,
      redirect: 'follow',
    }).then((res) => {
      if (res.status === 201) {
        res.json().then((data) => {
          setFormLoading(false)
          notify('success', `Data upload successful`)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setFormLoading(false)
        })
      }
    })
  }
  const uploadDumpData2 = () => {
    setFormLoading(true)
    const form_data = new FormData()
    form_data.append('file', profilePicture.file)
    form_data.append('category', category)
    fetch(`${process.env.REACT_APP_API_URL}/file-data/zip`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: form_data,
      redirect: 'follow',
    }).then((res) => {
      if (res.status === 201) {
        res.json().then((data) => {
          setFormLoading(false)
          notify('success', `Data upload successful`)
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {
          notify('error', data.message)
          setFormLoading(false)
        })
      }
    })
  }
  useEffect(() => {
    API.SearchFileCategories(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setCategories(data.results.map((item) => item.category))
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }, [])
  const formatCreateLabel = (inputValue) => {
    return `Create new Category: ${inputValue}`
  }
  function handleSearch(e) {
    if (e.length >= 3) {
      //  setIsLoading(true)
    }
  }
  const uploadPage = () => {
    switch (id) {
      case 'bulkUpload':
        return (
          <div className='form'>
            <div className='form_card'>
              <p>Choose what to upload</p>
              <select
                name=''
                id=''
                onChange={(e) => setFileName(e.target.value)}
              >
                <option value=''>Select an Upload type</option>
                <option value='employer'>Corporate</option>
                <option value='general1'>General Data (excel)</option>
                <option value='general2'>General Data (zip)</option>
                <option value='tax-payer'>Tax Payer</option>
                <option value='tax-transaction'>Tax Transaction</option>
                <option value='vehicle'>Vehicle</option>
              </select>
            </div>
            {(fileName === 'general1' || fileName === 'general2') && (
              <>
                <div className='form_card'>
                  <CreatableSelect
                    isClearable
                    isLoading={isLoading}
                    isSearchable={true}
                    options={categories.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    required
                    onChange={(value) => {
                      if (value) {
                        setCategory(value.value)
                      } else {
                        setCategory('')
                      }
                    }}
                    onInputChange={(e) => handleSearch(e)}
                    formatCreateLabel={formatCreateLabel}
                    placeholder={'-Search Category-'}
                  />
                </div>
              </>
            )}

            <div className='image_card'>
              <input
                type='file'
                name=''
                id='file'
                onChange={onSelectProductImage}
              />
              <label htmlFor='file'>
                <p className='label'>
                  {imgTru
                    ? profilePicture.name
                    : 'Upload data file (excel) or import file from your device'}
                </p>
                <div className='buttonf'>
                  <div>
                    <p>Import</p>
                    <BsDownload />
                  </div>
                </div>
              </label>
            </div>
            <div className='submit'>
              <button
                onClick={() => {
                  fileName === 'general1'
                    ? uploadDumpData1()
                    : fileName === 'general2'
                    ? uploadDumpData2()
                    : uploadData()
                }}
              >
                <p>Upload</p>
                <BsArrowRight />
              </button>
            </div>
          </div>
        )
      case 'bulkList':
        return (
          <>
            {loading ? (
              <div className='loader'>
                <div>
                  <img src={Loader} alt='' />
                </div>
              </div>
            ) : (
              <div className='valid'>
                <div className='table'>
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Upload type</th>
                        <th>Processing Status</th>
                        <th>Total rows</th>
                        <th>Processed</th>
                        <th>Passed</th>
                        <th>Failed</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bulkList.results.map((item, i) => (
                        <tr
                          key={item.id}
                          onClick={() =>
                            navigate(`/dashboard/bulk_response/${item.id}`)
                          }
                        >
                          <td>{new Date(item.createdDate).toDateString()}</td>
                          <td>
                            <p>{item.uploadType}</p>
                          </td>
                          <td>
                            <p>{item.processingStatus}</p>
                          </td>
                          <td>
                            <p>{item.results.total}</p>
                          </td>
                          <td>
                            <p>{item.results.processed}</p>
                          </td>
                          <td className='status'>
                            <p>{item.results.passed}</p>
                          </td>
                          <td>{item.results.failed}</td>
                          <td className='actions'>
                            <BiDotsHorizontalRounded />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  className='pagination-bar'
                  currentPage={currentPage}
                  totalCount={bulkList.totalPages}
                  pageSize={10}
                  onPageChange={(page) => {
                    setCurrentPage(page)
                    paginationClick(page)
                  }}
                />
              </div>
            )}
          </>
        )
      default:
        break
    }
  }

  return (
    <div className='uploadData'>
      {formLoading && (
        <div className='loadingd'>
          <img src={Loader} alt='' />
        </div>
      )}
      <div className='upload_list'>
        <div className='left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Upload data</h1>
        </div>
        <div className='right'>
          <div
            className={id === 'bulkUpload' ? 'card active' : 'card'}
            onClick={() => navigate('/dashboard/upload_data/bulkUpload')}
          >
            <BsCheckCircleFill />
            <p>Bulk Upload</p>
          </div>
          <div className='line'></div>
          <div
            className={id === 'bulkList' ? 'card active' : 'card'}
            onClick={() => navigate('/dashboard/upload_data/bulkList')}
          >
            <BsCheckCircleFill />
            <p>Bulk List</p>
          </div>
          {/* <div className='line'></div>
          <div className='card'>
            <BsCheckCircleFill />
            <p>Upload document</p>
          </div> */}
        </div>
      </div>
      <>{uploadPage()}</>
    </div>
  )
}

export default UploadData
