import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './TaxTransaction.scss'
import Loader from '../../../../Images/loader.gif'
import { CiSearch } from 'react-icons/ci'
import API from '../../../../Utility/API/API'
import Pagination from '../../Component/Pagination/Pagination'

function Settings({ IsUpload }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const navigate = useNavigate()
  const [fileSearch, setFileSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [taxTransactions, setTaxTransactions] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationType, setPaginationType] = useState('default')
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchTaxTransaction(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxTransactions(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {
          setLoading(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])

  const FindETax = () => {
    setLoading(true)
    setCurrentPage(1)
    fetch(
      `${process.env.REACT_APP_API_URL}/tax-transactions?searchTerm=${fileSearch}&limit=20&page=1`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxTransactions(data)
          setPaginationType('searchTerm')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const paginationClick = (num) => {
    setLoading(true)
    API.FetchTaxTransaction(num).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxTransactions(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const searchPaginationClick = (num) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/tax-transactions?searchTerm=${fileSearch}&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxTransactions(data)
          setLoading(false)
          setPaginationType('searchTerm')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='taxtransaction'>
      <div className='home_button'>
        {IsUpload && (
          <button onClick={() => navigate('/dashboard/upload_data/bulkUpload')}>
            Bulk upload data capture
          </button>
        )}
      </div>
      <div className='search_top'>
        <div className='form'>
          <input
            type='text'
            name=''
            id=''
            placeholder='Peter O'
            onChange={(e) => setFileSearch(e.target.value)}
          />
          <button onClick={() => FindETax()}>
            <CiSearch />
          </button>
        </div>
      </div>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>Payer Name</th>
              <th>Amount</th>
              <th>Payment</th>
              <th>MDA</th>
            </tr>
          </thead>
          <tbody>
            {taxTransactions.results.map((item, i) => (
              <tr
                key={item.id}
                onClick={() =>
                  navigate(`/dashboard/tax_transactions/${item.id}`)
                }
              >
                <td>{item.payerName}</td>
                <td>
                  <p>#{parseInt(item.amount).toLocaleString()}</p>
                </td>
                <td>
                  <p>{item.payment}</p>
                </td>
                <td>
                  <p>{item.mda ? item.mda : 'N/A'}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {paginationType === 'searchTerm' ? (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={taxTransactions.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            searchPaginationClick(page)
          }}
        />
      ) : (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={taxTransactions.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            paginationClick(page)
          }}
        />
      )}
    </div>
  )
}

export default Settings
