import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import API from '../../../../Utility/API/API'
import Loader from '../../../../Images/loader.gif'
import { BsArrowLeft } from 'react-icons/bs'

function IndividualPayment({ IsEdit }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [selecetedTransaction, setSelecetedTransaction] = useState({})
  const [ExtraDataKeys, setExtraDataKeys] = useState([])
  const [ExtraDataValues, setExtraDataValues] = useState([])
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchIndPaymentTransaction(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setSelecetedTransaction(data)
          if (Object.keys(data).includes('extra')) {
            setExtraDataKeys(Object.keys(data.extra))
            setExtraDataValues(Object.values(data.extra))
          }

          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='indtransaction'>
      <div className='top_button'>
        {selecetedTransaction.hasOwnProperty('taxPayerId') && (
          <button
            onClick={() =>
              navigate(
                `/dashboard/tax_payers/${selecetedTransaction.taxPayerId}`
              )
            }
          >
            View Tax Payer
          </button>
        )}
      </div>
      <div className='back'>
        <div className='back_left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Back</h1>
        </div>
      </div>
      <div className='transactions_wrap'>
        <div className='list1'>
          <div className='cards'>
            <p>Payment Ref Number</p>
            <h2>{selecetedTransaction.paymentRefNumber}</h2>
          </div>
          <div className='card'>
            <p>Receipt No</p>
            <h2>{selecetedTransaction.receiptNo}</h2>
          </div>
          <div className='card'>
            <p>Amount</p>
            <h2>
              {selecetedTransaction.amount
                ? selecetedTransaction.amount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : 'N/A'}
            </h2>
          </div>
          <div className='card'>
            <p>Trans Date</p>
            <h2>{new Date(selecetedTransaction.transDate).toDateString()}</h2>
          </div>
          <div className='card'>
            <p>Customer Name</p>
            <h2>{selecetedTransaction.customerName}</h2>
          </div>
          <div className='card'>
            <p>Customer Reference</p>
            <h2>
              {selecetedTransaction.customerReference === null
                ? 'Not Set'
                : selecetedTransaction.customerReference}
            </h2>
          </div>
          <div className='card'>
            <p>Merchant</p>
            <h2>
              {selecetedTransaction.merchant === null
                ? 'Not Set'
                : selecetedTransaction.merchant}
            </h2>
          </div>
          <div className='card'>
            <p>Location</p>
            <h2>{selecetedTransaction.location}</h2>
          </div>
          <div className='card'>
            <p>Settlement Date</p>
            <h2>
              {new Date(selecetedTransaction.settlementDate).toDateString()}
            </h2>
          </div>
          <div className='card'>
            <p>Processed By</p>
            <h2>
              {selecetedTransaction.processedBy === null
                ? 'Not Set'
                : selecetedTransaction.processedBy}
            </h2>
          </div>
          <div className='card'>
            <p>Payment</p>
            <h2>{selecetedTransaction.payment}</h2>
          </div>
          <div className='card'>
            <p>Payment Code</p>
            <h2>{selecetedTransaction.paymentCode}</h2>
          </div>
          <div className='card'>
            <p>Payment Method</p>
            <h2>
              {' '}
              {selecetedTransaction.paymentMethod === null
                ? 'Not Set'
                : selecetedTransaction.paymentMethod}
            </h2>
          </div>
          <div className='card'>
            <p>Payment Number</p>
            <h2>
              {' '}
              {selecetedTransaction.paymentNumber === null
                ? 'Not Set'
                : selecetedTransaction.paymentNumber}
            </h2>
          </div>
          <div className='card'>
            <p>Bank</p>
            <h2>{selecetedTransaction.bank}</h2>
          </div>
          <div className='card'>
            <p>Bill Number</p>
            <h2>{selecetedTransaction.billNumber}</h2>
          </div>
          <div className='card'>
            <p>Phone</p>
            <h2>
              {selecetedTransaction.phone === null
                ? 'Not Set'
                : selecetedTransaction.phone}
            </h2>
          </div>
          <div className='card'>
            <p>MDA</p>
            <h2>
              {selecetedTransaction.mda === null
                ? 'Not Set'
                : selecetedTransaction.mda}
            </h2>
          </div>
          <div className='card'>
            <p>Status</p>
            <h2>
              {selecetedTransaction.status === null
                ? 'Not Set'
                : selecetedTransaction.status}
            </h2>
          </div>
          <div className='cards'>
            <h2>Description</h2>
            <p>
              {selecetedTransaction.description === null
                ? 'Not Set'
                : selecetedTransaction.description}
            </p>
          </div>
          <div className='long'>
            <h1> Extra Data</h1>
            <table>
              <thead>
                <tr>
                  {ExtraDataKeys.map((item, i) => (
                    <th key={1 + i}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {ExtraDataValues.map((item, i) => (
                    <td key={1 + i}>{item}</td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndividualPayment
