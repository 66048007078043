import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Loader from '../../../../Images/loader.gif'
import { useNavigate, useParams } from 'react-router-dom'
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs'
import { TbReceiptTax, TbCircleMinus } from 'react-icons/tb'
import './PaymentTransactions.scss'
import API from '../../../../Utility/API/API'

function EditPayment({ notify }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [formLoading, setFormLoading] = useState(false)
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const [loading, setLoading] = useState(true)
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }

  const {
    register: TaxRegister,
    handleSubmit: TaxSubmit,
    formState: { errors: TaxErrors },
  } = useForm()
  const [selecetedTransaction, setSelecetedTransaction] = useState({})
  const [ObjK, setObjK] = useState('')
  const [ObjV, setObjV] = useState('')
  const [TypeObj, setTypeObj] = useState({})
  const [extraDataKeys, setExtraDataKeys] = useState([])
  const [extraDataValues, setExtraDataValues] = useState([])
  const pushIt = () => {
    TypeObj[ObjK] = ObjV
    setTypeObj(TypeObj)
    setObjK('')
    setObjV('')
    setExtraDataKeys(Object.keys(TypeObj))
    setExtraDataValues(Object.values(TypeObj))
  }
  const deleteKey = (value) => {
    delete TypeObj[value]
    setTypeObj(TypeObj)
    setExtraDataKeys(Object.keys(TypeObj))
    setExtraDataValues(Object.values(TypeObj))
  }
  const onTxEnroll = (formData) => {
    setFormLoading(true)
    const object = {
      paymentRefNumber: formData.paymentRefNumber,
      transDate: new Date(formData.transDate).toISOString(),
      billNumber: formData.billNumber,
      customerReference: formData.customerReference,
      receiptNo: formData.receiptNo,
      customerName: formData.customerName,
      payment: formData.payment,
      amount: formData.amount,
      paymentMethod: formData.paymentMethod,
      bank: formData.bank,
      location: formData.location,
      paymentCode: formData.paymentCode,
      paymentNumber: formData.paymentNumber,
      mda: formData.mda,
      merchant: formData.merchant,
      settlementDate: formData.settlementDate,
      phone: formData.phone,
      extra: TypeObj,
      description: formData.description,
      channel: 'API',
      status: 'Active',
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/payment-transactions/${selecetedTransaction.id}`,
      {
        method: 'put',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(object),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Payment Transaction Edited successfully`)
            setTimeout(() => {
              window.location.replace(
                `/dashboard/payment_transaction/${selecetedTransaction.id}`
              )
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }

  useEffect(() => {
    API.FetchIndPaymentTransaction(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setSelecetedTransaction(data)
          if (Object.keys(data).includes('extra')) {
            setTypeObj(data.extra)
            setExtraDataKeys(Object.keys(data.extra))
            setExtraDataValues(Object.values(data.extra))
          }
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='create_payment'>
      <div className='back'>
        <div className='back_left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Back</h1>
        </div>
      </div>
      <div className='enroll_main'>
        {formLoading && (
          <div className='formload'>
            <img src={Loader} alt='' />
          </div>
        )}
        <form className='left' onSubmit={TaxSubmit(onTxEnroll)}>
          <div className='top_form'>
            <div className='taxtrans'>
              <div className='left_top diff2'>
                <TbReceiptTax />
                <h2>Edit Payment Transaction</h2>
              </div>
            </div>
            <div className='top_six'>
              <div className='card'>
                <p>Payment Ref Number</p>
                <input
                  type='text'
                  name='paymentRefNumber'
                  id=''
                  {...TaxRegister('paymentRefNumber')}
                  defaultValue={
                    selecetedTransaction.paymentRefNumber === null
                      ? 'nil'
                      : selecetedTransaction.paymentRefNumber
                  }
                />
                {TaxErrors.paymentRefNumber && (
                  <h6 className='vError'>
                    {TaxErrors.paymentRefNumber.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>Trans Date</p>
                <input
                  type='text'
                  name='transDate'
                  id=''
                  {...TaxRegister('transDate')}
                  defaultValue={new Date(
                    `${selecetedTransaction.transDate}`
                  ).toLocaleDateString()}
                />
                {TaxErrors.transDate && (
                  <h6 className='vError'>{TaxErrors.transDate.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Bill Number</p>
                <input
                  type='text'
                  name='billNumber'
                  {...TaxRegister('billNumber')}
                  defaultValue={
                    selecetedTransaction.billNumber === null
                      ? 'nil'
                      : selecetedTransaction.billNumber
                  }
                />
                {TaxErrors.billNumber && (
                  <h6 className='vError'>{TaxErrors.billNumber.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Customer Reference</p>
                <input
                  type='text'
                  name='customerReference'
                  defaultValue={
                    selecetedTransaction.customerReference === null
                      ? 'nil'
                      : selecetedTransaction.customerReference
                  }
                  {...TaxRegister('customerReference')}
                />
                {TaxErrors.customerReference && (
                  <h6 className='vError'>
                    {TaxErrors.customerReference.message}
                  </h6>
                )}
              </div>
              <div className='card'>
                <p>Receipt No</p>
                <input
                  type='text'
                  name='receiptNo'
                  defaultValue={selecetedTransaction.receiptNo}
                  {...TaxRegister('receiptNo')}
                />
                {TaxErrors.receiptNo && (
                  <h6 className='vError'>{TaxErrors.receiptNo.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Customer Name</p>
                <input
                  type='text'
                  name='customerName'
                  defaultValue={selecetedTransaction.customerName}
                  {...TaxRegister('customerName')}
                />
                {TaxErrors.customerName && (
                  <h6 className='vError'>{TaxErrors.customerName.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Payment</p>
                <input
                  type='text'
                  name='payment'
                  defaultValue={selecetedTransaction.payment}
                  {...TaxRegister('payment')}
                />
                {TaxErrors.payment && (
                  <h6 className='vError'>{TaxErrors.payment.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Amount</p>
                <input
                  type='number'
                  name='amount'
                  defaultValue={parseInt(selecetedTransaction.amount)}
                  {...TaxRegister('amount')}
                />
                {TaxErrors.amount && (
                  <h6 className='vError'>{TaxErrors.amount.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Payment Method</p>
                <input
                  type='text'
                  name='paymentMethod'
                  defaultValue={
                    selecetedTransaction.paymentMethod === null
                      ? 'nil'
                      : selecetedTransaction.paymentMethod
                  }
                  {...TaxRegister('paymentMethod')}
                />
                {TaxErrors.paymentMethod && (
                  <h6 className='vError'>{TaxErrors.paymentMethod.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Bank</p>
                <input
                  type='text'
                  name='bank'
                  defaultValue={selecetedTransaction.bank}
                  {...TaxRegister('bank')}
                />
                {TaxErrors.bank && (
                  <h6 className='vError'>{TaxErrors.bank.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Location</p>
                <input
                  type='text'
                  name='location'
                  defaultValue={selecetedTransaction.location}
                  {...TaxRegister('location')}
                />
                {TaxErrors.location && (
                  <h6 className='vError'>{TaxErrors.location.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Payment Code</p>
                <input
                  type='text'
                  name='paymentCode'
                  defaultValue={selecetedTransaction.paymentCode}
                  {...TaxRegister('paymentCode')}
                />
                {TaxErrors.paymentCode && (
                  <h6 className='vError'>{TaxErrors.paymentCode.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Payment Number</p>
                <input
                  type='text'
                  name='paymentNumber'
                  defaultValue={
                    selecetedTransaction.paymentNumber === null
                      ? 'nil'
                      : selecetedTransaction.paymentNumber
                  }
                  {...TaxRegister('paymentNumber')}
                />
                {TaxErrors.paymentNumber && (
                  <h6 className='vError'>{TaxErrors.paymentNumber.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>MDA</p>
                <input
                  type='text'
                  name='mda'
                  defaultValue={selecetedTransaction.mda}
                  {...TaxRegister('mda')}
                />
                {TaxErrors.mda && (
                  <h6 className='vError'>{TaxErrors.mda.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Merchant</p>
                <input
                  type='text'
                  name='merchant'
                  defaultValue={
                    selecetedTransaction.merchant === null
                      ? 'nil'
                      : selecetedTransaction.merchant
                  }
                  {...TaxRegister('merchant')}
                />
                {TaxErrors.merchant && (
                  <h6 className='vError'>{TaxErrors.merchant.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Settlement Date</p>
                <input
                  type='text'
                  name='settlementDate'
                  id=''
                  {...TaxRegister('settlementDate')}
                  defaultValue={new Date(
                    `${selecetedTransaction.settlementDate}`
                  ).toLocaleDateString()}
                />
                {TaxErrors.settlementDate && (
                  <h6 className='vError'>{TaxErrors.settlementDate.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Phone</p>
                <input
                  type='tel'
                  name='phone'
                  defaultValue={
                    selecetedTransaction.phone === null
                      ? parseInt('00')
                      : selecetedTransaction.phone
                  }
                  {...TaxRegister('phone')}
                />
                {TaxErrors.phone && (
                  <h6 className='vError'>{TaxErrors.phone.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>Description</p>
                <input
                  type='text'
                  name='description'
                  defaultValue={
                    selecetedTransaction.description === null
                      ? 'nil'
                      : selecetedTransaction.description
                  }
                  {...TaxRegister('description')}
                />
                {TaxErrors.description && (
                  <h6 className='vError'>{TaxErrors.description.message}</h6>
                )}
              </div>
            </div>
            <div className='estra'>
              <h2>Extra Data</h2>
              <div className='top_three'>
                <div className='card'>
                  <p>Key</p>
                  <input
                    type='text'
                    name='billNumber'
                    onChange={(e) => setObjK(e.target.value)}
                    value={ObjK}
                  />
                </div>
                <div className='card'>
                  <p>Value</p>
                  <input
                    type='text'
                    name='customerReference'
                    onChange={(e) => setObjV(e.target.value)}
                    value={ObjV}
                  />
                </div>
                <div className='child_button' onClick={pushIt}>
                  <p className='button'>Add Field</p>
                </div>
              </div>
              <div className='showextra'>
                <table>
                  <thead>
                    <tr>
                      {extraDataKeys.map((item, i) => (
                        <th key={1 + i}>
                          <div className='div'>
                            <p> {item}</p>
                            <TbCircleMinus onClick={() => deleteKey(item)} />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {extraDataValues.map((item, i) => (
                        <td key={1 + i}>{item}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='submit'>
            <button>
              <p>Submit</p>
              <BsArrowRight />
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditPayment
