import React, { useState, useEffect } from 'react'
import './TaxPayer.scss'
import { CiSearch } from 'react-icons/ci'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../../Images/loader.gif'
import API from '../../../../Utility/API/API'
import Pagination from '../../Component/Pagination/Pagination'

function TaxPayer({ loginData, IsUpload, fixVIP }) {
  const authToken = sessionStorage.getItem('AdminId')
  const { handleRestrictionrToggle, handleDataModalToggle } = useGlobalContext()
  const navigate = useNavigate()
  const Token = sessionStorage.getItem('Admin-Id')
  const [loading, setLoading] = useState(true)
  const [taxPayer, setTaxPayer] = useState([])
  const [fileSearch, setFileSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationType, setPaginationType] = useState('default')

  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchTaxPayers(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  const paginationClick = (num) => {
    setLoading(true)
    API.FetchTaxPayers(num).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const FindETax = () => {
    setLoading(true)
    setCurrentPage(1)
    fetch(
      `${process.env.REACT_APP_API_URL}/tax-payers?searchTerm=${fileSearch}&limit=20&page=1`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setPaginationType('searchTerm')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const searchPaginationClick = (num) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/tax-payers?searchTerm=${fileSearch}&sortBy=firstName:asc&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxPayer(data)
          setLoading(false)
          setPaginationType('searchTerm')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }

  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='taxPayer'>
      <div className='search_top'>
        <div className='form'>
          <input
            type='text'
            name=''
            id=''
            placeholder='Peter O'
            onChange={(e) => setFileSearch(e.target.value)}
            defaultValue={fileSearch}
          />
          <button
            //  onClick={() => handleUserSearchToggle()}
            onClick={() => FindETax()}
          >
            <CiSearch />
          </button>
        </div>
      </div>
      <div className='top'>
        <h1>Tax Payer</h1>
        {IsUpload && (
          <div className='add'>
            <button onClick={() => handleDataModalToggle()}>
              Add New Payer
            </button>
          </div>
        )}
      </div>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>Payer Name</th>
              <th>Payer Gender</th>
              <th>Payer NIN</th>
              <th>Payer Phone</th>
              <th>OdTin</th>
            </tr>
          </thead>
          <tbody>
            {taxPayer.results.map((item, i) => (
              <tr
                key={item.id}
                className={item.isVip ? 'blur' : ''}
                onClick={() => {
                  if (fixVIP) {
                    navigate(`/dashboard/tax_payers/${item.id}`)
                  } else if (
                    loginData.role !== 'security admin' &&
                    item.isVip
                  ) {
                    handleRestrictionrToggle()
                  } else {
                    navigate(`/dashboard/tax_payers/${item.id}`)
                  }
                }}
              >
                <td>
                  {item.title} {item.lastName} {item.firstName}{' '}
                  {item.middleName}
                </td>
                <td>
                  <p> {item.gender}</p>
                </td>
                <td> {item.nin}</td>
                <td className='status'>
                  <p> {item.phone}</p>
                </td>
                <td> {item.odTin}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {paginationType === 'searchTerm' ? (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={taxPayer.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            searchPaginationClick(page)
          }}
        />
      ) : (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={taxPayer.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            paginationClick(page)
          }}
        />
      )}
    </div>
  )
}

export default TaxPayer
