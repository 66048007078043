import React from 'react'
import { useNavigate } from 'react-router-dom'
import './DataAdmin.scss'

function DataAdmin() {
  const navigate = useNavigate()
  return (
    <div className='datAdmin'>
      <div className='home_tag'>
        <h1>Data Upload</h1>
      </div>
      <div className='cover'>
        <div className='home_button'>
          <button onClick={() => navigate('/dashboard/upload_data/bulkUpload')}>
            Bulk upload data capture
          </button>
          <button onClick={() => navigate('/dashboard/enroll_payer')}>
            Manual Taxpayer capture{' '}
          </button>
          <button onClick={() => navigate('/dashboard/enroll_corporate')}>
            Manual corporate capture{' '}
          </button>
          <button onClick={() => navigate('/dashboard/record_payment')}>
            Manual Payment Transaction capture{' '}
          </button>
          <button onClick={() => navigate('/dashboard/upload_etax')}>
            E-Tax Upload
          </button>
        </div>
      </div>
    </div>
  )
}

export default DataAdmin
