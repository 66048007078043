import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import './UploadData.scss'
import {
  BsArrowLeft,
  BsCheckCircleFill,
  BsDownload,
  BsArrowRight,
} from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../../Images/loader.gif'

function UploadData({ notify }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const [formLoading, setFormLoading] = useState(false)
  const authToken = sessionStorage.getItem('AdminId')
  const navigate = useNavigate()

  const [imgTru, setImgTru] = useState(false)
  const [fileList, setFileList] = useState([])

  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const [reamin, setReamin] = useState('')
  const addDP = async () => {
    setFormLoading(true)
    try {
      const totalFiles = fileList.length
      let filesRemaining = totalFiles
      for (let i = 0; i < totalFiles; i++) {
        const file = fileList[i]
        const form_data = new FormData()
        form_data.append('fileName', file.name.slice(0, -4))
        form_data.append('file', file)
        setReamin(`Files in queue: ${filesRemaining}`)
        //Turn this back onnn
        // await new Promise((resolve) => {
        //   setTimeout(resolve, 30000) // 30-second delay
        // })
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/e-tax/uploads`,
          {
            method: 'post',
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            body: form_data,
            redirect: 'follow',
          }
        )

        if (response.status === 201) {
          filesRemaining--
          if (filesRemaining > 0) {
            setReamin(`Files in queue: ${filesRemaining}`)
          } else {
            setFormLoading(false)
            setReamin('Upload complete')
            notify('success', `E-tax files uploaded successfully`)
            setTimeout(() => {
              navigate('/dashboard/e_tax')
            }, 2000)
          }
        } else if (response.status === 401) {
          RefreshToken()
        } else {
          const data = await response.json()
          notify('error', data.message)
          setFormLoading(false)
        }
      }
    } catch (error) {
      notify('error', 'An error occurred')
      setFormLoading(false)
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the dropped files
    if (acceptedFiles.length > 0) {
      setReamin(`${acceptedFiles.length} files Selected`)
      setFileList(acceptedFiles)
      setImgTru(true)
    }
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  return (
    <div className='uploadData'>
      {formLoading && (
        <div className='loadingd'>
          <div className='dof'>
            <img src={Loader} alt='' />
            <h3>{reamin}</h3>
          </div>
        </div>
      )}
      <div className='upload_list'>
        <div className='left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Upload E-Tax</h1>
        </div>
      </div>
      <div className='form'>
        {/* <div className='form_card'>
          <p>File Name</p>
          <input
            type='text'
            onChange={(e) => setFileName(e.target.value)}
            value={fileName}
          />
        </div> */}
        <div className='image_card' {...getRootProps()}>
          <input {...getInputProps()} />
          <label htmlFor='file'>
            <p className='label'>
              {isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <p>
                  {imgTru
                    ? reamin
                    : 'Drag and drop a file here, or click to select'}
                </p>
              )}
            </p>
            <div className='buttonf'>
              <div>
                <p>Select file</p>
                <BsDownload />
              </div>
            </div>
          </label>
        </div>
        <div className='submit'>
          <button onClick={() => addDP()}>
            <p>Upload</p>
            <BsArrowRight />
          </button>
        </div>
      </div>
    </div>
  )
}

export default UploadData
