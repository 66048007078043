import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../../../../Images/loader.gif'
import { CiSearch } from 'react-icons/ci'
import API from '../../../../Utility/API/API'
import Pagination from '../../Component/Pagination/Pagination'
function Receipts({ IsUpload }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const navigate = useNavigate()
  const [fileSearch, setFileSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [taxTransactions, setTaxTransactions] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationType, setPaginationType] = useState('default')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const being = params.get('being')
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    if (being) {
      API.FetchReceiptsCategory(1, being).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTaxTransactions(data)
            setLoading(false)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            setLoading(false)
          })
        }
      })
    } else {
      API.FetchReceipts(1).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTaxTransactions(data)
            setLoading(false)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            setLoading(false)
          })
        }
      })
    }

    // eslint-disable-next-line
  }, [])

  const FindETax = () => {
    setLoading(true)
    setCurrentPage(1)
    if (being) {
      fetch(
        `${process.env.REACT_APP_API_URL}/receipts?being=${being}&searchTerm=${fileSearch}&limit=20&page=1`,
        {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      ).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTaxTransactions(data)
            setPaginationType('searchTerm')
            setLoading(false)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {})
        }
      })
    } else {
      fetch(
        `${process.env.REACT_APP_API_URL}/receipts?searchTerm=${fileSearch}&limit=20&page=1`,
        {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      ).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTaxTransactions(data)
            setPaginationType('searchTerm')
            setLoading(false)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {})
        }
      })
    }
  }
  const paginationClick = (num) => {
    setLoading(true)
    if (paginationType === 'default') {
      if (being) {
        API.FetchReceiptsCategory(num, being).then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setTaxTransactions(data)
              setLoading(false)
            })
          } else if (res.status === 401) {
            RefreshToken()
          } else {
            res.json().then((data) => {})
          }
        })
      } else {
        API.FetchReceipts(num).then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setTaxTransactions(data)
              setLoading(false)
            })
          } else if (res.status === 401) {
            RefreshToken()
          } else {
            res.json().then((data) => {})
          }
        })
      }
    } else if (paginationType === 'searchTerm') {
      if (being) {
        fetch(
          `${process.env.REACT_APP_API_URL}/receipts?being=${being}&searchTerm=${fileSearch}&limit=20&page=${num}`,
          {
            headers: {
              'content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          }
        ).then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setTaxTransactions(data)
              setPaginationType('searchTerm')
              setLoading(false)
            })
          } else if (res.status === 401) {
            RefreshToken()
          } else {
            res.json().then((data) => {})
          }
        })
      } else {
        fetch(
          `${process.env.REACT_APP_API_URL}/receipts?searchTerm=${fileSearch}&limit=20&page=${num}`,
          {
            headers: {
              'content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          }
        ).then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setTaxTransactions(data)
              setPaginationType('searchTerm')
              setLoading(false)
            })
          } else if (res.status === 401) {
            RefreshToken()
          } else {
            res.json().then((data) => {})
          }
        })
      }
    } else {
      if (being) {
        fetch(
          `${process.env.REACT_APP_API_URL}/receipts?being=${being}&startDate=${startDate}&endDate=${endDate}&limit=20&page=${num}`,
          {
            headers: {
              'content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          }
        ).then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setTaxTransactions(data)
              setLoading(false)
              setPaginationType('filterDate')
            })
          } else if (res.status === 401) {
            RefreshToken()
          } else {
            res.json().then((data) => {})
          }
        })
      } else {
        fetch(
          `${process.env.REACT_APP_API_URL}/receipts?startDate=${startDate}&endDate=${endDate}&limit=20&page=${num}`,
          {
            headers: {
              'content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
            },
          }
        ).then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setTaxTransactions(data)
              setLoading(false)
              setPaginationType('filterDate')
            })
          } else if (res.status === 401) {
            RefreshToken()
          } else {
            res.json().then((data) => {})
          }
        })
      }
    }
  }

  const DateFilter = () => {
    setLoading(true)
    setCurrentPage(1)
    if (being) {
      fetch(
        `${process.env.REACT_APP_API_URL}/receipts?being=${being}&startDate=${startDate}&endDate=${endDate}&limit=20&page=1`,
        {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      ).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTaxTransactions(data)
            setLoading(false)
            setPaginationType('filterDate')
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {})
        }
      })
    } else {
      fetch(
        `${process.env.REACT_APP_API_URL}/receipts?startDate=${startDate}&endDate=${endDate}&limit=20&page=1`,
        {
          headers: {
            'content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      ).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTaxTransactions(data)
            setLoading(false)
            setPaginationType('filterDate')
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {})
        }
      })
    }
  }
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='taxtransaction'>
      <div className='flexs'>
        <div className='search_top'>
          <h2>Search</h2>
          <div className='form'>
            <input
              type='text'
              name=''
              id=''
              placeholder='Peter O'
              onChange={(e) => setFileSearch(e.target.value)}
              value={fileSearch}
            />
            <button onClick={() => FindETax()}>
              <CiSearch />
            </button>
          </div>
        </div>
        <div className='date-filter'>
          <h2>Date filter</h2>
          <div className='date-filter-cover'>
            <div className='card'>
              <input
                type='date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <p>To</p>
            <div className='card'>
              <input
                type='date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className='submit'>
              <button onClick={() => DateFilter()}>Filter</button>
            </div>
          </div>
        </div>
      </div>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>Payer Name</th>
              <th>Amount</th>
              <th>Being</th>
              <th>MDA</th>
              <th>Reg Date</th>
            </tr>
          </thead>
          <tbody>
            {taxTransactions.results.map((item, i) => (
              <tr
                key={item.id}
                onClick={() => navigate(`/dashboard/receipt/${item.id}`)}
              >
                <td>{item.payerName}</td>
                <td>
                  <p># {parseFloat(item.paymentAmount).toLocaleString()}</p>
                </td>
                <td>
                  <p>{item.being}</p>
                </td>
                <td>{item.mda}</td>
                <td>{new Date(item.paymentDate).toDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        className='pagination-bar'
        currentPage={currentPage}
        totalCount={taxTransactions.totalPages}
        pageSize={10}
        onPageChange={(page) => {
          setCurrentPage(page)
          paginationClick(page)
        }}
      />
    </div>
  )
}

export default Receipts
