import React from 'react'
import './RestrictionModal.scss'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import Image from '.././../../../Images/restriction.png'

function RestrictionModal() {
  const { handleRestrictionrToggle } = useGlobalContext()
  return (
    <div className='restrictionModal'>
      <div className='top'>
        <div className='close'>
          <AiOutlineCloseCircle onClick={() => handleRestrictionrToggle()} />
        </div>
      </div>
      <div className='center'>
        <img src={Image} alt='' />
        <h1>Account Restricted</h1>
        <p>
          This profile have been restricted for your view, please contact the
          VIP admin to request access
        </p>
      </div>
    </div>
  )
}

export default RestrictionModal
