import React, { useState, useEffect } from 'react'
import './Home.scss'
import { TbReceiptTax, TbFileDescription } from 'react-icons/tb'
import { BsCircleFill } from 'react-icons/bs'
import Chart from 'react-apexcharts'
import { useNavigate } from 'react-router-dom'
import API from '../../../../Utility/API/API'
import Loader from '../../../../Images/loader.gif'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import Pagination from '../../Component/Pagination/Pagination'
import LoaderComp from '../../Component/Loader/LoaderComp'

function Home({ notify, IsUpload }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const { handleTaxpayerDrill } = useGlobalContext()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [taxMetrics, setTaxMetrics] = useState({})
  const [summary, setSummary] = useState({
    activeTaxPayers: 0,
    totalTaxPaid: 0,
    activeEmployer: 0,
  })
  const [barSeries, setBarSeries] = useState([])
  const [barData, setBarData] = useState([])
  const [fileData, setFileData] = useState({})
  const [fileDataLoading, setFileDataLoading] = useState(true)
  const [receiptsData, setReceiptsData] = useState({})
  const [receiptsDataLoading, setReceiptsDataLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPage2, setCurrentPage2] = useState(1)

  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchTaxpayerMetric().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxMetrics(data)
          API.FetchSummaryMetric().then((res) => {
            if (res.status === 200) {
              res.json().then((data) => {
                setSummary(data)
                setLoading(false)
              })
            } else if (res.status === 401) {
              notify('warn', 'Unauthorized!')
              setTimeout(() => {
                RefreshToken()
              }, 3000)
            } else {
              res.json().then((data) => {
                setLoading(false)
              })
            }
          })
        })
      } else if (res.status === 401) {
        notify('warn', 'Unauthorized!')
        setTimeout(() => {
          RefreshToken()
        }, 3000)
      } else {
        res.json().then((data) => {})
      }
    })
    API.FetchReceiptMetric(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setReceiptsData(data)
          setReceiptsDataLoading(false)
        })
      } else if (res.status === 401) {
        notify('warn', 'Unauthorized!')
        setTimeout(() => {
          RefreshToken()
        }, 3000)
      } else {
        res.json().then((data) => {})
      }
    })
    API.FetchFileDataMetric(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setFileData(data)
          setFileDataLoading(false)
        })
      } else if (res.status === 401) {
        notify('warn', 'Unauthorized!')
        setTimeout(() => {
          RefreshToken()
        }, 3000)
      } else {
        res.json().then((data) => {})
      }
    })

    // eslint-disable-next-line
  }, [])

  const fileDataPaginationClick = (num) => {
    setFileDataLoading(true)
    API.FetchFileDataMetric(num).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setFileData(data)
          setFileDataLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const receiptsDataPaginationClick = (num) => {
    setReceiptsDataLoading(true)
    API.FetchReceiptMetric(num).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setReceiptsData(data)
          setReceiptsDataLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }

  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='home_page'>
      <div className='home_tag'>
        <h1>File data counts</h1>
      </div>
      {fileDataLoading ? (
        <LoaderComp />
      ) : (
        <>
          <div className='home_card'>
            {fileData.results.map((item, i) => (
              <div
                className='card'
                key={1 + i}
                onClick={() =>
                  navigate(`/dashboard/file-data?category=${item.category}`)
                }
              >
                <div className='left dif'>
                  <div className='round'>
                    <TbFileDescription />
                  </div>
                </div>
                <div className='right'>
                  <p>{item.category}</p>
                  <h2>{item.count.toLocaleString()}</h2>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalCount={fileData.totalPages}
            pageSize={10}
            onPageChange={(page) => {
              setCurrentPage(page)
              fileDataPaginationClick(page)
            }}
          />
        </>
      )}

      <div className='home_tag'>
        <h1>Receipt data counts</h1>
      </div>
      {receiptsDataLoading ? (
        <LoaderComp />
      ) : (
        <>
          {' '}
          <div className='home_card'>
            {receiptsData.results.map((item, i) => (
              <div
                className='card'
                key={1 + i}
                onClick={() =>
                  navigate(`/dashboard/receipts?being=${item.being}`)
                }
              >
                <div className='left '>
                  <div className='round'>
                    <TbReceiptTax />
                  </div>
                </div>
                <div className='right'>
                  <p>{item.being}</p>
                  <h2>{item.count.toLocaleString()}</h2>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage2}
            totalCount={receiptsData.totalPages}
            pageSize={10}
            onPageChange={(page) => {
              setCurrentPage2(page)
              receiptsDataPaginationClick(page)
            }}
          />
        </>
      )}
    </div>
  )
}

export default Home
