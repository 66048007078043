import React, { useState } from 'react'
import './EnrollTaxtTrans.scss'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Loader from '../../../../Images/loader.gif'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { RiUser3Line } from 'react-icons/ri'

function EnrollTaxtTrans({ notify }) {
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const navigate = useNavigate()
  const [formLoading, setFormLoading] = useState(false)
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onEnroll = (formData) => {
    setFormLoading(true)
    const object = {
      tccApplicationsId: formData.tccApplicationsId,
      odTin: formData.odTin,
      tin: formData.tin,
      phone: formData.phone,
      jtbTin: formData.jtbTin,
      fileNo: formData.fileNo,
      applicationNumber: formData.applicationNumber,
      station: formData.station,
      income: formData.income,
      incomeYear: [
        formData.incomeYear1,
        formData.incomeYear2,
        formData.incomeYear3,
      ],
      taxPY: [formData.taxPY1, formData.taxPY2, formData.taxPY3],
      taxRecpY: [formData.taxRecpY1, formData.taxRecpY2, formData.taxRecpY3],
      dLevyY: [formData.dLevyY1, formData.dLevyY2, formData.dLevyY3],
      lucDate: [
        formData.lucDate1,
        formData.lucDate2,
        formData.lucDate3,
        formData.lucDate4,
      ],
      lucAmtY: [formData.lucAmtY1, formData.lucAmtY2, formData.lucAmtY3],
      lucY: [formData.lucY1, formData.lucY2, formData.lucY3, formData.lucY4],
      dLevyRecpY: [
        formData.dLevyRecpY1,
        formData.dLevyRecpY2,
        formData.dLevyRecpY3,
      ],
      appDateY: [
        formData.appDateY1,
        formData.appDateY2,
        formData.appDateY3,
        formData.appDateY4,
      ],
      regDate: [formData.regDate1, formData.regDate2, formData.regDate3],
      CurrentRegDate: formData.CurrentRegDate,
      expDate: formData.expDate,
      remark: formData.remark,
      channel: 'API',
      status: 'Active',
    }
    fetch(`${process.env.REACT_APP_API_URL}/tax-transactions`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(object),
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Tax transaction created successfully`)
            // setTimeout(() => {
            //   navigate('/dashboard/tax_payer')
            // }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  return (
    <div className='enrollTax'>
      <div className='top_button'>
        <button onClick={() => navigate('/dashboard/upload_data/bulkUpload')}>
          Bulk upload data capture
        </button>
      </div>
      <div className='upload_list'>
        <div className='left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Enroll a Payer</h1>
        </div>
      </div>
      <div className='enroll_main'>
        {formLoading && (
          <div className='formload'>
            <img src={Loader} alt='' />
          </div>
        )}
        <form className='left' onSubmit={handleSubmit(onEnroll)}>
          <div className='top_form'>
            <div className='top_six'>
              <div className='card'>
                <p>tccApplicationsId</p>
                <input
                  type='number'
                  name='tccApplicationsId'
                  id=''
                  {...register('tccApplicationsId', {
                    required: 'tccApplicationsId is required',
                  })}
                />
                {errors.tccApplicationsId && (
                  <h6 className='vError'>{errors.tccApplicationsId.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>odTin</p>
                <input
                  type='number'
                  name='odTin'
                  {...register('odTin', {
                    required: 'odTin is required',
                  })}
                />
                {errors.odTin && (
                  <h6 className='vError'>{errors.odTin.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>tin</p>
                <input
                  type='number'
                  name='tin'
                  {...register('tin', {
                    required: 'tin is required',
                  })}
                />
                {errors.tin && <h6 className='vError'>{errors.tin.message}</h6>}
              </div>
              <div className='card'>
                <p>phone</p>
                <input
                  type='number'
                  name='phone'
                  {...register('phone', {
                    required: 'phone is required',
                  })}
                />
                {errors.phone && (
                  <h6 className='vError'>{errors.phone.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>jtbTin</p>
                <input
                  type='number'
                  name='jtbTin'
                  {...register('jtbTin', {
                    required: 'jtbTin is required',
                  })}
                />
                {errors.jtbTin && (
                  <h6 className='vError'>{errors.jtbTin.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>fileNo</p>
                <input
                  type='text'
                  name='fileNo'
                  {...register('fileNo', {
                    required: 'fileNo is required',
                  })}
                />
                {errors.fileNo && (
                  <h6 className='vError'>{errors.fileNo.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>applicationNumber</p>
                <input
                  type='text'
                  name='applicationNumber'
                  {...register('applicationNumber', {
                    required: 'applicationNumber is required',
                  })}
                />
                {errors.applicationNumber && (
                  <h6 className='vError'>{errors.applicationNumber.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>station</p>
                <input
                  type='text'
                  name='station'
                  {...register('station', {
                    required: 'station is required',
                  })}
                />
                {errors.station && (
                  <h6 className='vError'>{errors.station.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>income</p>
                <input
                  type='text'
                  name='income'
                  {...register('income', {
                    required: 'income is required',
                  })}
                />
                {errors.income && (
                  <h6 className='vError'>{errors.income.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six'>
              <div className='card'>
                <p>incomeYear1</p>
                <input
                  type='text'
                  name='incomeYear1'
                  id=''
                  {...register('incomeYear1', {
                    required: 'incomeYear1 is required',
                  })}
                />
                {errors.incomeYear1 && (
                  <h6 className='vError'>{errors.incomeYear1.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>incomeYear2</p>
                <input
                  type='text'
                  name='incomeYear2'
                  id=''
                  {...register('incomeYear2', {
                    required: 'incomeYear2 is required',
                  })}
                />
                {errors.incomeYear2 && (
                  <h6 className='vError'>{errors.incomeYear2.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>incomeYear3</p>
                <input
                  type='text'
                  name='incomeYear3'
                  id=''
                  {...register('incomeYear3', {
                    required: 'incomeYear3 is required',
                  })}
                />
                {errors.incomeYear3 && (
                  <h6 className='vError'>{errors.incomeYear3.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six'>
              <div className='card'>
                <p>taxPY1</p>
                <input
                  type='text'
                  name='taxPY1'
                  id=''
                  {...register('taxPY1', {
                    required: 'taxPY1 is required',
                  })}
                />
                {errors.taxPY1 && (
                  <h6 className='vError'>{errors.taxPY1.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>taxPY2</p>
                <input
                  type='text'
                  name='taxPY2'
                  id=''
                  {...register('taxPY2', {
                    required: 'taxPY2 is required',
                  })}
                />
                {errors.taxPY2 && (
                  <h6 className='vError'>{errors.taxPY2.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>taxPY3</p>
                <input
                  type='text'
                  name='taxPY3'
                  id=''
                  {...register('taxPY3', {
                    required: 'taxPY3 is required',
                  })}
                />
                {errors.taxPY3 && (
                  <h6 className='vError'>{errors.taxPY3.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six'>
              <div className='card'>
                <p>taxRecpY1</p>
                <input
                  type='number'
                  name='taxRecpY1'
                  id=''
                  {...register('taxRecpY1', {
                    required: 'taxRecpY1 is required',
                  })}
                />
                {errors.taxRecpY1 && (
                  <h6 className='vError'>{errors.taxRecpY1.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>taxRecpY2</p>
                <input
                  type='number'
                  name='taxRecpY2'
                  id=''
                  {...register('taxRecpY2', {
                    required: 'taxRecpY2 is required',
                  })}
                />
                {errors.taxRecpY2 && (
                  <h6 className='vError'>{errors.taxRecpY2.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>taxRecpY3</p>
                <input
                  type='number'
                  name='taxRecpY3'
                  id=''
                  {...register('taxRecpY3', {
                    required: 'taxRecpY3 is required',
                  })}
                />
                {errors.taxRecpY3 && (
                  <h6 className='vError'>{errors.taxRecpY3.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six'>
              <div className='card'>
                <p>dLevyY1</p>
                <input
                  type='text'
                  name='dLevyY1'
                  id=''
                  {...register('dLevyY1', {
                    required: 'dLevyY1 is required',
                  })}
                />
                {errors.dLevyY1 && (
                  <h6 className='vError'>{errors.dLevyY1.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>dLevyY2</p>
                <input
                  type='text'
                  name='dLevyY2'
                  id=''
                  {...register('dLevyY2', {
                    required: 'dLevyY2 is required',
                  })}
                />
                {errors.dLevyY2 && (
                  <h6 className='vError'>{errors.dLevyY2.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>dLevyY3</p>
                <input
                  type='text'
                  name='dLevyY3'
                  id=''
                  {...register('dLevyY3', {
                    required: 'dLevyY3 is required',
                  })}
                />
                {errors.dLevyY3 && (
                  <h6 className='vError'>{errors.dLevyY3.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six diff'>
              <div className='card'>
                <p>lucDate1</p>
                <input
                  type='date'
                  name='lucDate1'
                  id=''
                  {...register('lucDate1', {
                    required: 'lucDate1 is required',
                  })}
                />
                {errors.lucDate1 && (
                  <h6 className='vError'>{errors.lucDate1.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>lucDate2</p>
                <input
                  type='date'
                  name='lucDate2'
                  id=''
                  {...register('lucDate2', {
                    required: 'lucDate2 is required',
                  })}
                />
                {errors.lucDate2 && (
                  <h6 className='vError'>{errors.lucDate2.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>lucDate3</p>
                <input
                  type='date'
                  name='lucDate3'
                  id=''
                  {...register('lucDate3', {
                    required: 'lucDate3 is required',
                  })}
                />
                {errors.lucDate3 && (
                  <h6 className='vError'>{errors.lucDate3.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>lucDate4</p>
                <input
                  type='date'
                  name='lucDate4'
                  id=''
                  {...register('lucDate4', {
                    required: 'lucDate4 is required',
                  })}
                />
                {errors.lucDate4 && (
                  <h6 className='vError'>{errors.lucDate4.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six'>
              <div className='card'>
                <p>lucAmtY1</p>
                <input
                  type='number'
                  name='lucAmtY1'
                  id=''
                  {...register('lucAmtY1', {
                    required: 'lucAmtY1 is required',
                  })}
                />
                {errors.lucAmtY1 && (
                  <h6 className='vError'>{errors.lucAmtY1.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>lucAmtY2</p>
                <input
                  type='number'
                  name='lucAmtY2'
                  id=''
                  {...register('lucAmtY2', {
                    required: 'lucAmtY2 is required',
                  })}
                />
                {errors.lucAmtY2 && (
                  <h6 className='vError'>{errors.lucAmtY2.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>lucAmtY3</p>
                <input
                  type='number'
                  name='lucAmtY3'
                  id=''
                  {...register('lucAmtY3', {
                    required: 'lucAmtY3 is required',
                  })}
                />
                {errors.lucAmtY3 && (
                  <h6 className='vError'>{errors.lucAmtY3.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six diff'>
              <div className='card'>
                <p>lucY1</p>
                <input
                  type='number'
                  name='lucY1'
                  id=''
                  {...register('lucY1', {
                    required: 'lucY1 is required',
                  })}
                />
                {errors.lucY1 && (
                  <h6 className='vError'>{errors.lucY1.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>lucY2</p>
                <input
                  type='number'
                  name='lucY2'
                  id=''
                  {...register('lucY2', {
                    required: 'lucY2 is required',
                  })}
                />
                {errors.lucY2 && (
                  <h6 className='vError'>{errors.lucY2.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>lucY3</p>
                <input
                  type='number'
                  name='lucY3'
                  id=''
                  {...register('lucY3', {
                    required: 'lucY3 is required',
                  })}
                />
                {errors.lucY3 && (
                  <h6 className='vError'>{errors.lucY3.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>lucY4</p>
                <input
                  type='number'
                  name='lucY4'
                  id=''
                  {...register('lucY4', {
                    required: 'lucY4 is required',
                  })}
                />
                {errors.lucY4 && (
                  <h6 className='vError'>{errors.lucY4.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six'>
              <div className='card'>
                <p>dLevyRecpY1</p>
                <input
                  type='number'
                  name='dLevyRecpY1'
                  id=''
                  {...register('dLevyRecpY1', {
                    required: 'dLevyRecpY1 is required',
                  })}
                />
                {errors.dLevyRecpY1 && (
                  <h6 className='vError'>{errors.dLevyRecpY1.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>dLevyRecpY2</p>
                <input
                  type='number'
                  name='dLevyRecpY2'
                  id=''
                  {...register('dLevyRecpY2', {
                    required: 'dLevyRecpY2 is required',
                  })}
                />
                {errors.dLevyRecpY2 && (
                  <h6 className='vError'>{errors.dLevyRecpY2.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>dLevyRecpY3</p>
                <input
                  type='number'
                  name='dLevyRecpY3'
                  id=''
                  {...register('dLevyRecpY3', {
                    required: 'dLevyRecpY3 is required',
                  })}
                />
                {errors.dLevyRecpY3 && (
                  <h6 className='vError'>{errors.dLevyRecpY3.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six diff'>
              <div className='card'>
                <p>appDateY1</p>
                <input
                  type='number'
                  name='appDateY1'
                  id=''
                  placeholder='2001'
                  {...register('appDateY1', {
                    required: 'appDateY1 is required',
                  })}
                />
                {errors.appDateY1 && (
                  <h6 className='vError'>{errors.appDateY1.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>appDateY2</p>
                <input
                  type='number'
                  name='appDateY2'
                  placeholder='2001'
                  id=''
                  {...register('appDateY2', {
                    required: 'appDateY2 is required',
                  })}
                />
                {errors.appDateY2 && (
                  <h6 className='vError'>{errors.appDateY2.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>appDateY3</p>
                <input
                  type='number'
                  name='appDateY3'
                  placeholder='2001'
                  id=''
                  {...register('appDateY3', {
                    required: 'appDateY3 is required',
                  })}
                />
                {errors.appDateY3 && (
                  <h6 className='vError'>{errors.appDateY3.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>appDateY4</p>
                <input
                  type='number'
                  name='appDateY4'
                  placeholder='2001'
                  id=''
                  {...register('appDateY4', {
                    required: 'appDateY4 is required',
                  })}
                />
                {errors.appDateY4 && (
                  <h6 className='vError'>{errors.appDateY4.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six'>
              <div className='card'>
                <p>regDate1</p>
                <input
                  type='date'
                  name='regDate1'
                  id=''
                  {...register('regDate1', {
                    required: 'regDate1 is required',
                  })}
                />
                {errors.regDate1 && (
                  <h6 className='vError'>{errors.regDate1.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>regDate2</p>
                <input
                  type='date'
                  name='regDate2'
                  id=''
                  {...register('regDate2', {
                    required: 'regDate2 is required',
                  })}
                />
                {errors.regDate2 && (
                  <h6 className='vError'>{errors.regDate2.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>regDate3</p>
                <input
                  type='date'
                  name='regDate3'
                  id=''
                  {...register('regDate3', {
                    required: 'regDate3 is required',
                  })}
                />
                {errors.regDate3 && (
                  <h6 className='vError'>{errors.regDate3.message}</h6>
                )}
              </div>
            </div>
            <div className='top_six'>
              <div className='card'>
                <p>CurrentRegDate</p>
                <input
                  type='date'
                  name='CurrentRegDate'
                  id=''
                  {...register('CurrentRegDate', {
                    required: 'CurrentRegDate is required',
                  })}
                />
                {errors.CurrentRegDate && (
                  <h6 className='vError'>{errors.CurrentRegDate.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>expDate</p>
                <input
                  type='date'
                  name='expDate'
                  id=''
                  {...register('expDate', {
                    required: 'expDate is required',
                  })}
                />
                {errors.expDate && (
                  <h6 className='vError'>{errors.expDate.message}</h6>
                )}
              </div>
              <div className='card'>
                <p>remark</p>
                <input
                  type='text'
                  name='remark'
                  id=''
                  {...register('remark', {
                    required: 'remark is required',
                  })}
                />
                {errors.remark && (
                  <h6 className='vError'>{errors.remark.message}</h6>
                )}
              </div>
            </div>
          </div>
          <div className='submit'>
            <button>
              <p>Save and proceed</p>
              <BsArrowRight />
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EnrollTaxtTrans
