import React, { useState, useEffect } from 'react'
import './UserPage.scss'
import API from '../../../../Utility/API/API'
import { useNavigate, useParams } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'
import Loader from '../../../../Images/loader.gif'

function UserTransaction() {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [taxTransactions, setTaxTransactions] = useState([])
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/tax-transactions?searchTerm=${id}&limit=20&page=1`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxTransactions(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  const paginationClick = (num) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/tax-transactions?searchTerm=${id}&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxTransactions(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='userTransaction'>
      <div className='home_button'>
        <h1>User Tax Transactions</h1>
      </div>
      <div className='back'>
        <div className='back_left'>
          <BsArrowLeft
            onClick={() =>
              taxTransactions.results.length > 0
                ? navigate(
                    `/dashboard/tax_payers/${taxTransactions.results[0].taxPayerId}`
                  )
                : navigate(-1)
            }
          />
          <h1>Back</h1>
        </div>
      </div>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>Application Number</th>
              <th>File No.</th>
              <th>Od Tin</th>
              <th>Station</th>
              {/* <th>Income</th> */}
              <th>Reg Date</th>
              <th>Exp Date</th>
            </tr>
          </thead>
          <tbody>
            {taxTransactions.results.map((item, i) => (
              <tr
                key={item.id}
                onClick={() =>
                  navigate(`/dashboard/tax_transactions/${item.id}`)
                }
              >
                <td>{item.applicationNumber}</td>
                <td className='file'>
                  <p>{item.fileNo}</p>
                </td>
                <td>
                  <p>{item.odTin}</p>
                </td>
                <td>
                  <p>{item.station}</p>
                </td>
                {/* <td className='status'>
                  <p>{item.income}</p>
                </td> */}
                <td>{new Date(item.CurrentRegDate).toDateString()}</td>
                <td>{new Date(item.expDate).toDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className='new_table'>
        <div className='table-body'>
          <div class='row header'>
            <div className='cell'>Full Name</div>
            <div className='cell'>Age</div>
            <div className='cell'>Job Title</div>
            <div className='cell'>Location</div>
          </div>
        </div>
      </div> */}
      <div className='pagination'>
        <ul>
          {[...Array(taxTransactions.totalPages)].map((item, i) => (
            <li
              key={i + 1}
              onClick={() => paginationClick(i + 1)}
              className={taxTransactions.page === i + 1 ? 'active' : ''}
            >
              {i + 1}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default UserTransaction
