import React, { useState } from 'react'
import './Add_user.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import { useForm } from 'react-hook-form'
import LoaderComp from '../Loader/LoaderComp'
import { FaTimes } from 'react-icons/fa'

function AddSocialMedia({ notify }) {
  const navigate = useNavigate()
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const { handleAddSocialToggle } = useGlobalContext()
  const [formLoading, setFormLoading] = useState(false)
  const { id, page } = useParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const [mediaUrl, setMediaUrl] = useState('')
  const [mediaUrls, setMediaUrls] = useState([])

  const addMediaUrl = (e) => {
    e.preventDefault()
    if (mediaUrl.trim() !== '') {
      setMediaUrls([...mediaUrls, mediaUrl])
      setMediaUrl('')
    }
  }
  const deleteMediaUrl = (index) => {
    const updatedNames = mediaUrls.filter((_, i) => i !== index)
    setMediaUrls(updatedNames)
  }
  const onRegister = (formData) => {
    setFormLoading(true)
    let type = ''
    if (page === 'tax_payers') {
      type = 'tax-payers'
    } else {
      type = 'employers'
    }
    fetch(`${process.env.REACT_APP_API_URL}/${type}/${id}/social-posts`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        ...formData,
        mediaUrls: mediaUrls,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Social media post added successful`)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })

      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  function shortenURL(url, maxLength) {
    if (url.length <= maxLength) {
      return url
    } else {
      return url.slice(0, maxLength - 3) + '...'
    }
  }
  return (
    <div className='add_user'>
      <div className='top'>
        <h1>Social Media Post</h1>
        <div className='close'>
          <AiOutlineCloseCircle onClick={() => handleAddSocialToggle()} />
        </div>
      </div>
      <form className='form' onSubmit={handleSubmit(onRegister)}>
        <div className='card long'>
          <p>Platform</p>
          <input
            type='text'
            id=''
            placeholder='platform'
            name='platform'
            {...register('platform', {
              required: 'Platform is required',
            })}
          />
          {errors.platform && (
            <h6 className='vError'>{errors.platform.message}</h6>
          )}
        </div>
        <div className='card long'>
          <p>Post Url</p>
          <input
            type='text'
            id=''
            placeholder='post Url'
            name='postUrl'
            {...register('postUrl', {
              required: 'post Url is required',
            })}
          />
          {errors.postUrl && (
            <h6 className='vError'>{errors.postUrl.message}</h6>
          )}
        </div>
        <div className='card long'>
          <p>Content</p>
          <textarea
            name=''
            id=''
            cols='30'
            rows='10'
            {...register('content', {
              required: 'Content is required',
            })}
            placeholder='content'
          ></textarea>
          {errors.content && (
            <h6 className='vError'>{errors.content.message}</h6>
          )}
        </div>
        <div className='card long'>
          <p>Media Url</p>
          <div className='inputs'>
            <input
              type='text'
              value={mediaUrl}
              onChange={(e) => setMediaUrl(e.target.value)}
              placeholder='Enter a name'
            />
            <button onClick={addMediaUrl}>Add mediaUrl</button>
          </div>
          {mediaUrls.map((item, i) => (
            <div className='showAdded' key={i}>
              <div className='one'>
                <h5>{shortenURL(item, 40)}</h5>
              </div>
              <div className='two'>
                <FaTimes onClick={() => deleteMediaUrl(i)} />
              </div>
            </div>
          ))}
        </div>
        {formLoading ? (
          <LoaderComp />
        ) : (
          <div className='buttons'>
            <span className='button' onClick={() => handleAddSocialToggle()}>
              Cancel
            </span>
            <button className='save'>Save</button>
          </div>
        )}
      </form>
    </div>
  )
}

export default AddSocialMedia
