import React, { useState, useEffect } from 'react'
import './NewHome.scss'
import { useNavigate } from 'react-router-dom'
import {
  FaUserAlt,
  FaUsers,
  FaUserPlus,
  FaCloudUploadAlt,
  FaFilePdf,
} from 'react-icons/fa'
import { MdCorporateFare } from 'react-icons/md'
import { FcOpenedFolder } from 'react-icons/fc'
import { BsGraphUp } from 'react-icons/bs'
import API from '../../../../Utility/API/API'

function NewHome({ notify, IsUpload }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const UserData = JSON.parse(sessionStorage.getItem('userData'))
  const [totalTransactions, setTotalTransactions] = useState(0)
  const [totalRevenues, setTotalRevenues] = useState(0)
  const [summary, setSummary] = useState({
    activeTaxPayers: 0,
    totalTaxPaid: 0,
    activeEmployer: 0,
  })
  const [corporate, setCorporate] = useState(0)
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchTaxpayerMetric().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          // setTaxMetrics(data)
          API.FetchSummaryMetric().then((res) => {
            if (res.status === 200) {
              res.json().then((data) => {
                setSummary(data)
              })
            } else if (res.status === 401) {
              notify('warn', 'Unauthorized!')
              setTimeout(() => {
                RefreshToken()
              }, 3000)
            } else {
              res.json().then((data) => {})
            }
          })
        })
      } else if (res.status === 401) {
        notify('warn', 'Unauthorized!')
        setTimeout(() => {
          RefreshToken()
        }, 3000)
      } else {
        res.json().then((data) => {})
      }
    })
    API.FetchEmployerMetric().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setCorporate(data.count)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }, [])
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrls = [`${process.env.REACT_APP_API_URL}/e-tax?limit=5`]
        const newApiUrls = [
          `${process.env.REACT_APP_API_URL}/metrics/file-data`,
          // `${process.env.REACT_APP_API_URL}/metrics/receipt`,
        ]

        const fetchPromises = apiUrls.map((url) =>
          fetch(url, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
        )
        const fetchNewPromises = newApiUrls.map((url) =>
          fetch(url, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
        )

        const responses = await Promise.all(fetchPromises)
        const newResponses = await Promise.all(fetchNewPromises)
        const jsonPromises = responses.map((response) => response.json())
        const newJsonPromises = newResponses.map((response) => response.json())
        const results = await Promise.all(jsonPromises)
        const newResults = await Promise.all(newJsonPromises)

        // Calculate the total number of results
        const total = results.reduce((sum, obj) => sum + obj.totalResults, 0)
        const newTotal = newResults.reduce((sum, obj) => sum + obj.count, 0)
        setTotalRevenues(total)
        setTotalTransactions(total + newTotal)
      } catch (error) {}
    }

    fetchData()
  }, [authToken])

  const shootSearch = (e) => {
    e.preventDefault()
    navigate(
      `/dashboard/search-result?search=${encodeURIComponent(
        e.target.search.value
      )}`
    )
  }
  return (
    <div className='newHome'>
      <div className='cover'>
        {(UserData.role === 'admin' ||
          UserData.role === 'executive user' ||
          UserData.role === 'security admin') && (
          <div className='cards'>
            <div
              className='card'
              onClick={() => navigate('/dashboard/tax_payer')}
            >
              <h1>{summary.activeTaxPayers.toLocaleString()}</h1>
              <div className='cons'>
                <p>Individuals</p>
                <FaUserAlt />
              </div>
            </div>
            <div
              className='card'
              onClick={() => navigate('/dashboard/corporate')}
            >
              <h1>{corporate.toLocaleString()}</h1>
              <div className='cons'>
                <p>Corporate</p>
                <MdCorporateFare />
              </div>
            </div>
            <div
              className='card'
              onClick={() => navigate('/dashboard/tax_payer')}
            >
              <h1>{(summary.activeTaxPayers + corporate).toLocaleString()}</h1>
              <div className='cons'>
                <p>Total TaxPayers</p>
                <FaUsers />
              </div>
            </div>
            <div className='card' onClick={() => navigate('/dashboard/e_tax')}>
              <h1>{totalRevenues.toLocaleString()}</h1>
              <div className='cons'>
                <p>Total PDF Records</p>
                <FaFilePdf />
              </div>
            </div>
            <div className='card'>
              <h1>{totalTransactions.toLocaleString()}</h1>
              <div
                className='cons'
                title='This is a sum of all PDF records, File data and Receipts'
              >
                <p>Total Tax Records</p>
                <FcOpenedFolder />
              </div>
            </div>
            {/* <div
              className='card'
              onClick={() => navigate('/dashboard/transactions_category')}
            >
              <div className='cons'>
                <p>Transaction by Category</p>
                <FaUserAlt />
              </div>
            </div> */}
            <div
              className='card'
              onClick={() => navigate('/dashboard/admin_user')}
            >
              <h1>10</h1>
              <div className='cons'>
                <p>Users</p>
                <FaUserPlus />
              </div>
            </div>
            {IsUpload && (
              <div
                className='card'
                onClick={() => navigate('/dashboard/upload_data/bulkUpload')}
              >
                <div className='cons'>
                  <p>Upload</p>
                  <FaCloudUploadAlt />
                </div>
              </div>
            )}
            {/* <div className='card'>
              <div className='cons'>
                <p>Reports</p>
                <BsFillFileEarmarkTextFill />
              </div>
            </div> */}
            <div
              className='card'
              onClick={() => navigate('/dashboard/analytics')}
            >
              <div className='cons'>
                <p>Analytics</p>
                <BsGraphUp />
              </div>
            </div>
          </div>
        )}
        {UserData.role === 'general user' && (
          <div className='cards'>
            <div
              className='card'
              onClick={() => navigate('/dashboard/tax_payer')}
            >
              <h1>{summary.activeTaxPayers.toLocaleString()}</h1>
              <div className='cons'>
                <p>Individuals</p>
                <FaUserAlt />
              </div>
            </div>
            <div
              className='card'
              onClick={() => navigate('/dashboard/corporate')}
            >
              <h1>{corporate.toLocaleString()}</h1>
              <div className='cons'>
                <p>Corporate</p>
                <MdCorporateFare />
              </div>
            </div>
            <div
              className='card'
              onClick={() => navigate('/dashboard/tax_payer')}
            >
              <h1>
                <h1>
                  {(summary.activeTaxPayers + corporate).toLocaleString()}
                </h1>
              </h1>
              <div className='cons'>
                <p>Total TaxPayers</p>
                <FaUsers />
              </div>
            </div>
            <div className='card'>
              <h1>{totalTransactions.toLocaleString()}</h1>
              <div className='cons'>
                <p>Total Tax Records</p>
                <FaUsers />
              </div>
            </div>
            {/* <div
              className='card'
              onClick={() => navigate('/dashboard/transactions_category')}
            >
              <div className='cons'>
                <p>Transaction by Category</p>
                <FaUserAlt />
              </div>
            </div> */}
          </div>
        )}
        <form className='search' onSubmit={shootSearch}>
          <input
            type='text'
            required
            placeholder='search mobile number, TIN number, email, full name'
            name='search'
          />
          <div className='buttons'>
            <button>Search</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewHome
