import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Admin_user.scss'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { BsRecordCircleFill } from 'react-icons/bs'
import Loader from '../../../../Images/loader.gif'
import API from '../../../../Utility/API/API'

function Admin_user({ notify }) {
  const navigate = useNavigate()
  const Token = sessionStorage.getItem('Admin-Id')
  const { handleAddUserToggle } = useGlobalContext()
  const [loading, setLoading] = useState(true)
  const [userList, setUserList] = useState([])
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchUsers(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUserList(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        notify('warn', 'Unauthorized!')
        setTimeout(() => {
          RefreshToken()
        }, 3000)
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  const paginationClick = (num) => {
    setLoading(true)
    API.FetchUsers(num).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUserList(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }

  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='admin_user'>
      <div className='top'>
        <h1>Manage Admin Users</h1>
        <div className='add'>
          <button onClick={() => handleAddUserToggle()}>Add User</button>
        </div>
      </div>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact Information</th>
              <th>Role</th>
              <th>Status</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userList.results
              .sort((a, b) => a.name - b.name)
              .map((item, i) => (
                <tr key={item.id}>
                  <td>
                    {item.firstName} {item.lastName}
                  </td>
                  <td>
                    <p>{item.mobile}</p>
                    <p>{item.email}</p>
                  </td>
                  <td>{item.role}</td>
                  {item.isActive ? (
                    <td className='status'>
                      <div className='dot'>
                        <BsRecordCircleFill />
                      </div>
                      <p>Active</p>
                    </td>
                  ) : (
                    <td className='status'>
                      <div className='dots'>
                        <BsRecordCircleFill />
                      </div>
                      <p>Inactive</p>
                    </td>
                  )}
                  <td>{new Date(item.createdDate).toDateString()}</td>
                  <td className='actions'>
                    <BiDotsHorizontalRounded
                      onClick={() =>
                        navigate(`/dashboard/admin_users/${item.id}`)
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className='pagination'>
        <ul>
          {[...Array(userList.totalPages)].map((item, i) => (
            <li
              key={i + 1}
              onClick={() => paginationClick(i + 1)}
              className={userList.page === i + 1 ? 'active' : ''}
            >
              {i + 1}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Admin_user
