import React from 'react'
import './UserSearch.scss'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useGlobalContext } from '../../../../Utility/Context/Context'

function UserSearch() {
  const { handleUserSearchToggle } = useGlobalContext()
  return (
    <div className='userSearch'>
      <div className='top'>
        <p>Peter O</p>
        <div className='close'>
          <AiOutlineCloseCircle onClick={() => handleUserSearchToggle()} />
        </div>
      </div>
      <div className='form'>
        <div className='form_wrapper'>
          <div className='card'>
            <input
              type='text'
              name=''
              id=''
              placeholder='First, middle, last name, phone'
            />
          </div>
          <div className='card'>
            <input type='text' name='' id='' placeholder='TAX ID' />
          </div>
          <div className='card'>
            <input type='text' name='' id='' placeholder='Employee ID' />
          </div>
          <div className='card'>
            <select name='' id=''>
              <option value=''> --EmploymentType --</option>
            </select>
          </div>
          <div className='card'>
            <select name='' id=''>
              <option value=''> --Coporate --</option>
            </select>
          </div>
          <div className='card'>
            <select name='' id=''>
              <option value=''> --MDA --</option>
            </select>
          </div>
          <div className='card'>
            <select name='' id=''>
              <option value=''> --Gender --</option>
            </select>
          </div>
          <div className='card'>
            <select name='' id=''>
              <option value=''> --LGA --</option>
            </select>
          </div>
          <div className='card'>
            <select name='' id=''>
              <option value=''> --TAX--</option>
            </select>
          </div>
          <div className='card'>
            <select name='' id=''>
              <option value=''> -- Has TIN --</option>
            </select>
          </div>
          <div className='card'>
            <select name='' id=''>
              <option value=''> Reg. start date</option>
            </select>
          </div>
          <div className='card'>
            <select name='' id=''>
              <option value=''> Reg. end date</option>
            </select>
          </div>
        </div>
        <div className='buttons'>
          <button onClick={() => handleUserSearchToggle()}>Cancel</button>
          <button className='save'>Submit Search</button>
        </div>
      </div>
    </div>
  )
}

export default UserSearch
