import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../../../../Images/loader.gif'
import { CiSearch } from 'react-icons/ci'
import API from '../../../../Utility/API/API'
import Pagination from '../../Component/Pagination/Pagination'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import LoaderComp from '../../Component/Loader/LoaderComp'

function FileData({ notify }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const navigate = useNavigate()
  const [fileSearch, setFileSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [taxTransactions, setTaxTransactions] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationType, setPaginationType] = useState('default')
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const category = params.get('category')
  const [showEditCategory, setShowEditCategory] = useState(false)
  const [currentCategoryData, setCurrentCategoryData] = useState({})
  const [formLoading, setFormLoading] = useState(false)

  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchFileDataCategory(1, decodeURIComponent(category)).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxTransactions(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {
          setLoading(false)
        })
      }
    })
    API.FetchCategoriesWithIds().then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          const foundCategory = data.results.find(
            (item) => item.category === category
          )
          setCurrentCategoryData(foundCategory)
        })
      } else if (res.status === 401) {
        notify('warn', 'Unauthorized!')
        setTimeout(() => {
          RefreshToken()
        }, 3000)
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])

  const FindETax = () => {
    setLoading(true)
    setCurrentPage(1)
    fetch(
      `${process.env.REACT_APP_API_URL}/file-data?category=${category}&keyword=${fileSearch}&limit=12&page=1`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxTransactions(data)
          setPaginationType('searchTerm')
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const paginationClick = (num) => {
    setLoading(true)
    if (category) {
      API.FetchFileDataCategory(num, category).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTaxTransactions(data)
            setLoading(false)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            setLoading(false)
          })
        }
      })
    } else {
      API.FetchFileData(num).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setTaxTransactions(data)
            setLoading(false)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            setLoading(false)
          })
        }
      })
    }
  }
  const searchPaginationClick = (num) => {
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/file-data?category=${category}&keyword=${fileSearch}&limit=12&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setTaxTransactions(data)
          setLoading(false)
          setPaginationType('searchTerm')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onRegister = (formData) => {
    setFormLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/file-data/categories/${currentCategoryData.id}`,
      {
        method: 'put',
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          ...formData,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `Category name changed successfully`)
            setTimeout(() => {
              window.location.replace(
                `/dashboard/file-data?category=${formData.category}`
              )
            }, 2000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })
      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='taxtransaction'>
      {showEditCategory && (
        <div className='edit-modal'>
          <div className='modal-body'>
            <div className='top'>
              <h1>Edit Category Name</h1>
              <div className='close'>
                <AiOutlineCloseCircle
                  onClick={() => setShowEditCategory(false)}
                />
              </div>
            </div>
            <form className='form' onSubmit={handleSubmit(onRegister)}>
              <div className='card long'>
                <p>Category Name</p>
                <input
                  type='text'
                  id=''
                  name='category'
                  {...register('category', {
                    required: 'Category Name is required',
                  })}
                  defaultValue={currentCategoryData.category}
                />
                {errors.category && (
                  <h6 className='vError'>{errors.category.message}</h6>
                )}
              </div>
              {formLoading ? (
                <LoaderComp />
              ) : (
                <div className='buttons'>
                  <span
                    className='button'
                    onClick={() => setShowEditCategory(false)}
                  >
                    Cancel
                  </span>
                  <button className='save'>Save</button>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
      <div className='search_top'>
        <div className='form'>
          <input
            type='text'
            name=''
            id=''
            placeholder='Peter O'
            value={fileSearch}
            onChange={(e) => setFileSearch(e.target.value)}
          />
          <button onClick={() => FindETax()}>
            <CiSearch />
          </button>
        </div>
      </div>
      <div className='edit'>
        <button onClick={() => setShowEditCategory(true)}>
          Edit Category Name
        </button>
      </div>
      <div className='cards'>
        {taxTransactions.results.map((item, x) => (
          <div
            className='card'
            key={1 + x}
            onClick={() => navigate(`/dashboard/general_data/${item.id}`)}
          >
            <p>
              Category: <b>{item.category}</b>
            </p>
            {Object.entries(item.fileData)
              .slice(0, 3)
              .map((nuk, i) => (
                <p key={i + 1}>
                  {nuk[0]}: <b> {nuk[1]}</b>
                </p>
              ))}
          </div>
        ))}
      </div>
      {paginationType === 'searchTerm' ? (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={taxTransactions.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            searchPaginationClick(page)
          }}
        />
      ) : (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={taxTransactions.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            paginationClick(page)
          }}
        />
      )}
    </div>
  )
}

export default FileData
