import React, { useState } from 'react'
import './Add_user.scss'
import { useNavigate } from 'react-router-dom'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import Loader from '../../../../Images/loader.gif'
import { useForm } from 'react-hook-form'

function AddUser({ notify, fixVIP }) {
  const navigate = useNavigate()
  const authToken = sessionStorage.getItem('AdminId')
  const Token = sessionStorage.getItem('Admin-Id')
  const { handleAddUserToggle } = useGlobalContext()
  const [formLoading, setFormLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  const onRegister = (formData) => {
    setFormLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        ...formData,
        email: formData.email.toLowerCase(),
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          res.json().then((data) => {
            setFormLoading(false)
            notify('success', `${data.role} created successful`)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
        } else if (res.status === 401) {
          RefreshToken()
        } else {
          res.json().then((data) => {
            notify('error', data.message)
            setFormLoading(false)
          })
        }
      })

      .catch((err) => {
        notify('error', 'An error ocurred. Unable to reach server')
        setFormLoading(false)
      })
  }
  return (
    <div className='add_user'>
      <div className='top'>
        <h1>New User</h1>
        <div className='close'>
          <AiOutlineCloseCircle onClick={() => handleAddUserToggle()} />
        </div>
      </div>
      <form className='form' onSubmit={handleSubmit(onRegister)}>
        <div className='card'>
          <p>First Name</p>
          <input
            type='text'
            id=''
            placeholder='First Name'
            name='firstName'
            {...register('firstName', {
              required: 'First name is required',
            })}
          />
          {errors.firstName && (
            <h6 className='vError'>{errors.firstName.message}</h6>
          )}
        </div>
        <div className='card'>
          <p>Last Name</p>
          <input
            type='text'
            id=''
            placeholder='Last Name'
            name='lastName'
            {...register('lastName', {
              required: 'Last name is required',
            })}
          />
          {errors.lastName && (
            <h6 className='vError'>{errors.lastName.message}</h6>
          )}
        </div>
        <div className='card'>
          <p>Email Address</p>
          <input
            type='email'
            id=''
            placeholder='Email Address'
            name='email'
            {...register('email', {
              required: 'Email is required',
            })}
          />
          {errors.email && <h6 className='vError'>{errors.email.message}</h6>}
        </div>
        <div className='card'>
          <p>Password</p>
          <input
            type='text'
            id=''
            placeholder='Set User Password'
            name='password'
            {...register('password', {
              required: 'Last name is required',
            })}
          />
          {errors.password && (
            <h6 className='vError'>{errors.password.message}</h6>
          )}
        </div>
        <div className='card'>
          <p>Phone Number</p>
          <input
            type='tel'
            id=''
            placeholder='Phone Number'
            name='mobile'
            {...register('mobile', {
              required: 'Phone number is required',
            })}
          />
          {errors.mobile && <h6 className='vError'>{errors.mobile.message}</h6>}
        </div>
        <div className='card long'>
          <p>User Role</p>
          <select
            id=''
            name='role'
            {...register('role', {
              required: 'Role is required',
            })}
          >
            <option value=''>- Select -</option>
            <option value='admin'>Admin</option>
            <option value='data admin'>Data Admin</option>
            <option value='general user'>General User</option>
            {fixVIP && <option value='security admin'>Security Admin</option>}
            <option value='executive user'>Executive user</option>
          </select>
          {errors.role && <h6 className='vError'>{errors.role.message}</h6>}
        </div>
        {formLoading ? (
          <div className='loaders'>
            <img src={Loader} alt='' />
          </div>
        ) : (
          <div className='buttons'>
            <span className='button' onClick={() => handleAddUserToggle()}>
              Cancel
            </span>
            <button className='save'>Save</button>
          </div>
        )}
      </form>
    </div>
  )
}

export default AddUser
