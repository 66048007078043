import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import API from '../../../../Utility/API/API'
import Loader from '../../../../Images/loader.gif'
import { BsArrowLeft } from 'react-icons/bs'

function IndividualCorporate({ IsEdit }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [selecetedCoporate, setSelecetedCoporate] = useState({})
  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchIndCorporate(id).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setSelecetedCoporate(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
    // eslint-disable-next-line
  }, [])
  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='indtransaction'>
      <div className='top_button'>
        {IsEdit && (
          <button
            onClick={() =>
              navigate(`/dashboard/edit_corporate/${selecetedCoporate.id}`)
            }
          >
            Edit Corporate
          </button>
        )}
      </div>
      <div className='back'>
        <div className='back_left'>
          <BsArrowLeft onClick={() => navigate(-1)} />
          <h1>Back</h1>
        </div>
      </div>
      <div className='transactions_wrap'>
        <div className='list1'>
          <div className='cards'>
            <p>Trade Name</p>
            <h2>{selecetedCoporate.tradeName}</h2>
          </div>
          <div className='cards' style={{ paddingBottom: '15px' }}>
            <p>Business Name</p>
            <h2>{selecetedCoporate.businessName}</h2>
          </div>
          <div className='card'>
            <p>TIN</p>
            <h2>{selecetedCoporate.tin}</h2>
          </div>
          <div className='card'>
            <p>Enrollee Id</p>
            <h2>{selecetedCoporate.enrolleeId}</h2>
          </div>
          <div className='card'>
            <p>Date Of Incorporation</p>
            <h2>
              {new Date(selecetedCoporate.dateOfIncorporation).toDateString()}
            </h2>
          </div>
          <div className='card'>
            <p>Commencement Date</p>
            <h2>
              {new Date(selecetedCoporate.commencementDate).toDateString()}
            </h2>
          </div>
          <div className='card'>
            <p>Registration Date</p>
            <h2>
              {new Date(selecetedCoporate.registrationDate).toDateString()}
            </h2>
          </div>
          <div className='card'>
            <p>Registration Number</p>
            <h2>{selecetedCoporate.registrationNumber}</h2>
          </div>
          <div className='card'>
            <p>Organisation Type</p>
            <h2>{selecetedCoporate.organisationType}</h2>
          </div>
          <div className='card'>
            <p>Line Of Business</p>
            <h2>{selecetedCoporate.lineOfBusiness}</h2>
          </div>
          <div className='card'>
            <p>Phone</p>
            <h2>
              {selecetedCoporate.phone === null
                ? 'Not Set'
                : selecetedCoporate.phone}
            </h2>
          </div>
          <div className='card'>
            <p>Email</p>
            <h2>
              {selecetedCoporate.email === null
                ? 'Not Set'
                : selecetedCoporate.email}
            </h2>
          </div>
          <div className='card'>
            <p>Number Of Employees</p>
            <h2>{selecetedCoporate.numberOfEmployees}</h2>
          </div>
          <div className='card'>
            <p>Tax Authority</p>
            <h2>
              {selecetedCoporate.taxAuthority === null
                ? 'Not Set'
                : selecetedCoporate.taxAuthority}
            </h2>
          </div>
          <div className='card'>
            <p>House No</p>
            <h2>{selecetedCoporate.houseNo}</h2>
          </div>
          <div className='card'>
            <p>Street</p>
            <h2>
              {' '}
              {selecetedCoporate.street === null
                ? 'Not Set'
                : selecetedCoporate.street}
            </h2>
          </div>
          <div className='card'>
            <p>City</p>
            <h2>
              {' '}
              {selecetedCoporate.city === null
                ? 'Not Set'
                : selecetedCoporate.city}
            </h2>
          </div>
          <div className='card'>
            <p>LGA</p>
            <h2>{selecetedCoporate.lga}</h2>
          </div>
          <div className='card'>
            <p>State</p>
            <h2>{selecetedCoporate.state}</h2>
          </div>

          <div className='card'>
            <p>State Of Residence</p>
            <h2>
              {selecetedCoporate.stateOfResidence === null
                ? 'Not Set'
                : selecetedCoporate.stateOfResidence}
            </h2>
          </div>
          <div className='card'>
            <p>Status</p>
            <h2>
              {selecetedCoporate.status === null
                ? 'Not Set'
                : selecetedCoporate.status}
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndividualCorporate
