import React from 'react'
import './DataModal.scss'
import GForm from '../../../../Images/google-forms.png'
import Upload from '../../../../Images/upload.png'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

function DataModal() {
  const navigate = useNavigate()
  const { handleDataModalToggle } = useGlobalContext()
  return (
    <div className='dataModal'>
      <div className='top'>
        <div className='close'>
          <AiOutlineCloseCircle onClick={() => handleDataModalToggle()} />
        </div>
      </div>
      <div className='wrapper'>
        <div className='card'>
          <img src={Upload} alt='' />
          <h2>Bulk upload data capture</h2>
          <button
            onClick={() => {
              navigate('/dashboard/upload_data/bulkUpload')
              handleDataModalToggle()
            }}
          >
            Capture
          </button>
        </div>
        <div className='card'>
          <img src={GForm} alt='' />
          <h2>Manual data capture </h2>
          <button
            onClick={() => {
              navigate('/dashboard/enroll_payer')
              handleDataModalToggle()
            }}
          >
            Capture
          </button>
        </div>
      </div>
    </div>
  )
}

export default DataModal
