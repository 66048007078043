import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './PaymentTransactions.scss'
import Loader from '../../../../Images/loader.gif'
import { CiSearch } from 'react-icons/ci'
import API from '../../../../Utility/API/API'
import Pagination from '../../Component/Pagination/Pagination'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { TbReceiptTax } from 'react-icons/tb'
import { BiUser } from 'react-icons/bi'

function PaymentTransaction({ IsUpload }) {
  const Token = sessionStorage.getItem('Admin-Id')
  const authToken = sessionStorage.getItem('AdminId')
  const navigate = useNavigate()
  const [fileSearch, setFileSearch] = useState('')
  const [fileSearchTo, setFileSearchTo] = useState('')
  const [loading, setLoading] = useState(true)
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
  const [paymentTransaction, setPaymentTransaction] = useState([])
  const [paginationType, setPaginationType] = useState('default')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRevenues, setTotalRevenues] = useState(0)

  const RefreshToken = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/refresh-tokens`, {
      method: 'post',
      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: Token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            sessionStorage.setItem('AdminId', data.tokens.access.token)
            sessionStorage.setItem('Admin-Id', data.tokens.refresh.token)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        } else {
          res.json().then((data) => {
            sessionStorage.removeItem('AdminId')
            sessionStorage.removeItem('Admin-Id')
            navigate('/')
          })
        }
      })
      .catch((rr) => {})
  }
  useEffect(() => {
    API.FetchPaymentTransaction(1).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setPaymentTransaction(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {
          setLoading(false)
        })
      }
    })
    // eslint-disable-next-line
  }, [])
  const FindETax = () => {
    setTotalRevenues(0)
    setLoading(true)
    setCurrentPage(1)
    fetch(
      `${process.env.REACT_APP_API_URL}/payment-transactions?searchTerm=${fileSearch}&limit=20&page=1`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setPaymentTransaction(data)
          setLoading(false)
          setShowAdvancedSearch(false)
          setPaginationType('searchTerm')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const FindETaxDate = () => {
    setTotalRevenues(0)
    setLoading(true)
    setCurrentPage(1)
    fetch(
      `${process.env.REACT_APP_API_URL}/payment-transactions?from=${fileSearch}&to=${fileSearchTo}&limit=20&page=1`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setPaymentTransaction(data)
          setLoading(false)
          setShowAdvancedSearch(false)
          setPaginationType('searchDate')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const MDAList = [
    'Agricultural Input and Supply Agency',
    'Board of Internal Revenue',
    'BUREAU OF PUBLIC PROCUREMENT (BPP)',
    'Cabinet and Special Services Department',
    'Christian Welfare Board',
    'Civil Service Commission',
    'Cocoa Revolution Office',
    'Customary Court of Appeal',
    'Hospital Management Board',
    'Liaison Office, Abuja',
    'Liaison Office, Lagos',
    'MDA',
    'Micro Credit Agency',
    'Ministry of Agriculture',
    'Ministry of Culture and Tourism',
    'Ministry of Environment',
    'Ministry of Health',
    'Ministry of Information',
    'Ministry of Justice',
    'Ministry of Local Government and Chieftaincy Affai',
    'Ministry of Natural Resources',
    'Ministry of Transport',
    'Ministry of Women Affairs and Social Development',
    'Ministry of Works and Transport',
    'Ministry of Youth Development and Sports',
    'Ministry of Physical Planning and Urban Developmen',
    'Ministry of Education',
    'Ministry of Lands and Housing',
    'Ministry of Commerce and Industry',
    'Office of the State Auditor General',
    'Ondo State Development and Property Corporation',
    'Ondo State Judiciary',
    'Ondo State Pensions Transitional Department',
    'Ondo State Security Network Agency - Amotekun Corp',
    'Ondo State Signage Agency',
    'Ondo State Waste Management',
    'Pools Bettings and Lotteries Board',
    'Science Technical and Vocational Board',
    'State Information Technology Agency (SITA)',
    'State Universal Basic Education Board (SUBEB) Head',
    'Teaching Service Commission',
  ]
  const paginationClick = (num) => {
    setLoading(true)
    API.FetchPaymentTransaction(num).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setPaymentTransaction(data)
          setLoading(false)
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const searchPaginationClick = (num) => {
    setTotalRevenues(0)
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/payment-transactions?searchTerm=${fileSearch}&sortBy=customerName:asc&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setPaymentTransaction(data)
          setLoading(false)
          setPaginationType('searchTerm')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }
  const searchDatePaginationClick = (num) => {
    setTotalRevenues(0)
    setLoading(true)
    fetch(
      `${process.env.REACT_APP_API_URL}/payment-transactions?from=${fileSearch}&to=${fileSearchTo}&sortBy=transDate:asc&limit=20&page=${num}`,
      {
        headers: {
          'content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setPaymentTransaction(data)
          setTotalRevenues(
            data.results.reduce(
              (a, v) => (a = parseInt(a) + parseInt(v.amount)),
              0
            )
          )
          setLoading(false)
          setPaginationType('searchDate')
        })
      } else if (res.status === 401) {
        RefreshToken()
      } else {
        res.json().then((data) => {})
      }
    })
  }

  return loading ? (
    <div className='loader'>
      <div>
        <img src={Loader} alt='' />
      </div>
    </div>
  ) : (
    <div className='paymenttransaction'>
      {showAdvancedSearch && (
        <div className='overlays'>
          <div className='userSearch'>
            <div className='top'>
              <p>Advanced Search</p>
              <div className='close'>
                <AiOutlineCloseCircle
                  onClick={() => setShowAdvancedSearch(false)}
                />
              </div>
            </div>
            <div className='form'>
              <div className='tag'>
                <h2>Filter By Dates</h2>
              </div>
              <div className='form_wrapper1'>
                <div className='card'>
                  <label htmlFor=''>from</label>
                  <input
                    type='date'
                    placeholder='from'
                    onChange={(e) => setFileSearch(e.target.value)}
                  />
                </div>
                <div className='card'>
                  <label htmlFor=''>to</label>
                  <input
                    type='date'
                    onChange={(e) => setFileSearchTo(e.target.value)}
                  />
                </div>
                <div className='button'>
                  <button onClick={() => FindETaxDate()}>Search</button>
                </div>
              </div>
              <div className='tag'>
                <h2>Filter By MDA</h2>
              </div>
              <div className='form_wrapper2'>
                <div className='card'>
                  <select
                    name=''
                    id=''
                    onChange={(e) => setFileSearch(e.target.value)}
                  >
                    <option value=''> --MDA --</option>
                    {MDAList.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='button'>
                  <button onClick={() => FindETax()}>Search</button>
                </div>
              </div>
              <div className='tag'>
                <h2>Filter By Process</h2>
              </div>
              <div className='form_wrapper2'>
                <div className='card'>
                  <select
                    name=''
                    id=''
                    onChange={(e) => setFileSearch(e.target.value)}
                  >
                    <option value=''> Processed By</option>
                    <option value='etransact'> Etransact</option>
                    <option value='payDirect'> Pay Direct</option>
                  </select>
                </div>
                <div className='button'>
                  <button onClick={() => FindETax()}>Search</button>
                </div>
              </div>
              <div className='buttons'>
                <button onClick={() => setShowAdvancedSearch(false)}>
                  Close Search
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {IsUpload && (
        <div className='home_button'>
          <button onClick={() => navigate('/dashboard/record_payment')}>
            Manual Payment upload
          </button>
          <button onClick={() => navigate('/dashboard/upload_data/bulkUpload')}>
            Bulk upload data capture
          </button>
        </div>
      )}
      <div className='home_card'>
        <div className='card'>
          <div className='left dif'>
            <div className='round'>
              <BiUser />
            </div>
          </div>
          <div className='right'>
            <p>Total payment transactions</p>
            <h2>{paymentTransaction.totalResults.toLocaleString()}</h2>
          </div>
        </div>
        <div className='card'>
          <button onClick={() => setShowAdvancedSearch(true)}>
            Advanced Search
          </button>
        </div>
      </div>
      <div className='search_top'>
        <div className='form'>
          <input
            type='text'
            name=''
            id=''
            placeholder='General Search'
            onChange={(e) => setFileSearch(e.target.value)}
            defaultValue={fileSearch}
          />
          <button
            // onClick={() => handleUserSearchToggle()}
            onClick={() => FindETax()}
          >
            <CiSearch />
          </button>
        </div>
      </div>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>Payment Ref Number</th>
              <th>Customer Name</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {paymentTransaction.results
              .filter((item) => item.paymentRefNumber !== null)
              .map((item, i) => (
                <tr
                  key={item.id}
                  onClick={() =>
                    navigate(`/dashboard/payment_transaction/${item.id}`)
                  }
                >
                  <td>{item.paymentRefNumber}</td>
                  <td>
                    <p>{item.customerName}</p>
                  </td>
                  <td>
                    {item.transDate ? (
                      <p>{new Date(item.transDate).toDateString()}</p>
                    ) : (
                      <p>{new Date(item.settlementDate).toDateString()}</p>
                    )}
                  </td>
                  <td>
                    <p>
                      {item.amount
                        ? item.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 'N/A'}
                      {}
                    </p>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* <div className='pagination'>
        <ul>
          {[...Array(paymentTransaction.totalPages)].map((item, i) => (
            <li
              key={i + 1}
              onClick={() => paginationClick(i + 1)}
              className={paymentTransaction.page === i + 1 ? 'active' : ''}
            >
              {i + 1}
            </li>
          ))}
        </ul>
      </div> */}
      {paginationType === 'default' && (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={paymentTransaction.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            paginationClick(page)
          }}
        />
      )}
      {paginationType === 'searchTerm' && (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={paymentTransaction.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            searchPaginationClick(page)
          }}
        />
      )}
      {paginationType === 'searchDate' && (
        <Pagination
          className='pagination-bar'
          currentPage={currentPage}
          totalCount={paymentTransaction.totalPages}
          pageSize={10}
          onPageChange={(page) => {
            setCurrentPage(page)
            searchDatePaginationClick(page)
          }}
        />
      )}
    </div>
  )
}

export default PaymentTransaction
