import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
const ProtectedRoute = ({ loggedIn, notify }) => {
  const token = sessionStorage.getItem('AdminId')

  if (loggedIn) {
    return <Outlet />
  } else if (token) {
    return <Outlet />
  } else {
    return (
      <>
        {notify('warn', 'Access denied! Please login')}
        <Navigate to='/' />
      </>
    )
  }
}
export default ProtectedRoute
