import React, { useState, useEffect } from 'react'
import './TaxpayerDrill.scss'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useGlobalContext } from '../../../../Utility/Context/Context'
import { BiUser } from 'react-icons/bi'
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs'
import { FaFemale } from 'react-icons/fa'
import { ImManWoman } from 'react-icons/im'
import { RiGovernmentLine } from 'react-icons/ri'
import StateData from '../../Dashboard_pages/EnrollPayer/StateData'
import API from '../../../../Utility/API/API'

function TaxpayerDrill() {
  const { handleTaxpayerDrill, taxpayerDrill } = useGlobalContext()
  const SelectState = (e) => {
    const foundState = StateData.find((item) => item.state.name === e)
    setLocalGovernment(foundState.state.locals)
  }
  const [localGovernment, setLocalGovernment] = useState([])
  const [male, setMale] = useState(0)
  const [female, setFemale] = useState(0)
  const [single, setSingle] = useState(0)
  const [married, setMarried] = useState(0)
  const [lga, setLga] = useState('LGA')
  const [lgaData, setLgaData] = useState(0)
  const [lgaLoading, setLgaLoading] = useState(false)

  const FetchInfo = () => {
    API.FetchTaxPayerDrill('gender', 'male')
      .then((res) => res.json())
      .then((data) => {
        setMale(data.totalResults)
      })
    API.FetchTaxPayerDrill('gender', 'female')
      .then((res) => res.json())
      .then((data) => {
        setFemale(data.totalResults)
      })
    API.FetchTaxPayerDrill('maritalStatus', 'Single')
      .then((res) => res.json())
      .then((data) => {
        setSingle(data.totalResults)
      })
    API.FetchTaxPayerDrill('maritalStatus', 'Married')
      .then((res) => res.json())
      .then((data) => {
        setMarried(data.totalResults)
      })
  }
  useEffect(() => {
    if (!taxpayerDrill) {
      FetchInfo()
    }
  }, [taxpayerDrill])

  const FindLGA = (id) => {
    setLga(id)
    setLgaData(0)
    setLgaLoading(true)
    API.FetchTaxPayerDrill('lga', id)
      .then((res) => res.json())
      .then((data) => {
        setLgaData(data.totalResults)
        setLgaLoading(false)
      })
  }

  return (
    <div className='taxpayerDrill'>
      <div className='top'>
        <h2>Taxpayers Drill</h2>
        <div className='close'>
          <AiOutlineCloseCircle onClick={() => handleTaxpayerDrill()} />
        </div>
      </div>
      <div className='home_card'>
        <div className='card'>
          <div className='left dif'>
            <div className='round'>
              <BsGenderMale />
            </div>
          </div>
          <div className='right'>
            <p>Male taxpayers</p>
            <h2>{male.toLocaleString()}</h2>
          </div>
        </div>
        <div className='card'>
          <div className='left '>
            <div className='round'>
              <BsGenderFemale />
            </div>
          </div>
          <div className='right'>
            <p>Female taxpayers</p>
            <h2>{female.toLocaleString()}</h2>
          </div>
        </div>
        <div className='card'>
          <div className='left dif'>
            <div className='round'>
              <FaFemale />
            </div>
          </div>
          <div className='right'>
            <p>Single taxpayers</p>
            <h2>{single.toLocaleString()}</h2>
          </div>
        </div>
        <div className='card'>
          <div className='left'>
            <div className='round'>
              <ImManWoman />
            </div>
          </div>
          <div className='right'>
            <p>Married taxpayers</p>
            <h2>{married.toLocaleString()}</h2>
          </div>
        </div>
      </div>
      <div className='inputs'>
        <h2>Filter By LGA</h2>
        <div className='top_form'>
          <div className='top_four'>
            <div className='cards'>
              <p>STATE</p>
              <select
                name='state'
                id=''
                onChange={(e) => {
                  SelectState(e.target.value)
                }}
              >
                <option value=''>Select</option>
                {StateData.map((item) => (
                  <option value={item.state.name} key={item.state.name}>
                    {item.state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='cards'>
              <p>LOCAL GOVT. AREA </p>
              <select
                name='lga'
                id=''
                onChange={(e) => FindLGA(e.target.value)}
              >
                <option value=''>Select</option>
                {localGovernment.map((item) => (
                  <option value={item.name} key={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='home_card'>
        <div className='cards'>
          <div className='left dif'>
            <div className='round'>
              <RiGovernmentLine />
            </div>
          </div>
          <div className='right'>
            <p>{lga}</p>
            {lgaLoading ? <h2>...</h2> : <h2>{lgaData.toLocaleString()}</h2>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaxpayerDrill
