import React, { useState, useContext } from 'react'

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [toggleSidebar, setToggleSidebar] = useState('false')
  const [addUser, setAddUser] = useState('false')
  const [addSocial, setAddSocial] = useState('false')
  const [userSearch, setUserSearch] = useState('false')
  const [restriction, setRestriction] = useState('false')
  const [dataModal, setDataModal] = useState('false')
  const [selectedETax, setSelectedETax] = useState({})
  const [taxpayerDrill, setTaxpayerDrill] = useState('false')
  const [showLinkData, setShowLinkData] = useState('false')
  const [linkdata, setLinkdata] = useState({})

  const handleDataModalToggle = () => {
    setDataModal(!dataModal)
  }
  const handleRestrictionrToggle = () => {
    setRestriction(!restriction)
  }
  const handleUserSearchToggle = () => {
    setUserSearch(!userSearch)
  }
  const handleAddUserToggle = () => {
    setAddUser(!addUser)
  }
  const handleAddSocialToggle = () => {
    setAddSocial(!addSocial)
  }
  const handleSidebarToggle = () => {
    setToggleSidebar(!toggleSidebar)
  }
  const handleTaxpayerDrill = () => {
    setTaxpayerDrill(!taxpayerDrill)
  }
  const handleLinkData = (data) => {
    setShowLinkData(!showLinkData)
    setLinkdata(data)
  }

  return (
    <AppContext.Provider
      value={{
        selectedETax,
        setSelectedETax,
        dataModal,
        handleDataModalToggle,
        restriction,
        handleRestrictionrToggle,
        userSearch,
        handleUserSearchToggle,
        handleSidebarToggle,
        toggleSidebar,
        handleAddUserToggle,
        handleAddSocialToggle,
        addUser,
        addSocial,
        handleTaxpayerDrill,
        taxpayerDrill,
        showLinkData,
        linkdata,
        handleLinkData,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
